import React, {  useEffect } from 'react';

function FreeArenaMenu() {
  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);

  

  // סגנון עבור האלמנט העליון
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto', // במקום 100vh, כדי שהגובה יתאים לתוכן
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // שקיפות חלקית
    borderRadius: '10px',
    padding: '40px', // הגדלת הרווח בפנים
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // מסגרת עם צל עדין
    maxWidth: '600px', // לרספונסיביות
    margin: '50px auto', // ממרכז באופן אוטומטי ומוסיף מרווח מלמעלה ומלמטה
    gap: '20px', // מוסיף רווח בין האלמנטים בפנים
  };


  const buttonStyle = {
    marginTop: '20px', // מרווח מוגדל מהתיבה
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#007BFF',
    color: 'white',
    fontSize: '16px',
    width: '80%'
  };

  return (
    <div style={containerStyle}>
      <h1>הזירה החופשית</h1>
      
      <button onClick={()=>{window.location.href = `/FreeArena/registration`;}} style={buttonStyle}>רישום שרות חדש</button>
      <button onClick={()=>{window.location.href = `/FreeArena/content/instructions`;}} style={buttonStyle}>הוראות</button>
      <button onClick={()=>{window.location.href = `/FreeArena/content/terms`;}} style={buttonStyle}>תנאי שימוש</button>
      <button onClick={()=>{window.location.href = `/FreeArena/competition/registration`;}} style={buttonStyle}>רישום לתחרות השירותים</button>
      {/* <button onClick={()=>{window.location.href = `/FreeArena/trackingInterface`;}} style={buttonStyle}>ממשק מעקב</button>
      <button onClick={()=>{window.location.href = `/FreeArena/`;}} style={buttonStyle}></button>
      <button onClick={()=>{window.location.href = `/FreeArena/`;}} style={buttonStyle}></button> */}
    </div>
  );
}

export default FreeArenaMenu;
