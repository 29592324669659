import React from 'react';

const ServiceTerms = () => {
  return (
    <div>
    <h2>תנאי שימוש למערכת עזרן, הזירה החופשית</h2>
    
    <p>
      ברוכים הבאים למערכת עזרן. השימוש במערכת כפוף לתנאי השימוש המפורטים להלן. אנא קראו תנאים אלה בעיון, שכן השימוש במערכת מהווה הסכמה לתנאים אלה.
    </p>

    <h3> כללי</h3>
    <p>
      הזירה החופשית נועדה לספק פלטפורמה לשירותים טלפוניים אינטראקטיביים. השימוש במערכת ניתן בשלב זה ללא תשלום, אך ייתכן שבעתיד ייגבה תשלום עבור השירותים. עזרן שומרת לעצמה את הזכות לשנות את תנאי השימוש ואת מדיניות התשלומים בכל עת.
    </p>

    <h3> הפסקת השירות</h3>
    <p>
      המערכת עזרן רשאית להפסיק את השירות בכל עת, לכל המשתמשים או לחלקם, ללא הודעה מראש ולפי שיקול דעתה הבלעדי. עזרן אינה אחראית לכל נזק או הפסד שייגרם עקב הפסקת השירות.
    </p>

    <h3> התאמה לאורח החיים החרדי</h3>
    <p>
      השימוש במערכת עזרן מוגבל לשירותים שתואמים את אורח החיים החרדי. עזרן שומרת לעצמה את הזכות להסיר או לחסום כל שירות שאינו תואם עקרונות אלה, לפי שיקול דעתה הבלעדי.
    </p>

    <h3> אחריות</h3>
    <p>
      עזרן אינה אחראית לכל נזק שייגרם לנותן השירות כתוצאה משימוש במערכת, בין אם הנזק נגרם על ידי המשתמשים ובין אם על ידי המערכת עצמה.
      <br />
      נותן השירות אחראי על כל נזק שייגרם על ידו, הן למערכת עזרן והן למשתמשים בשירותיו.
    </p>

    <h3> בורר מוסכם</h3>
    <p>
      כל מחלוקת שתתעורר בין הצדדים (בין אם בין עזרן לנותן השירות ובין אם בין עזרן למשתמשים) תיושב על ידי בית הדין הישר והטוב בירושלים, שייקבע כבורר מוסכם לכל מחלוקת. החלטות בית הדין יהיו סופיות ומחייבות את כל הצדדים.
    </p>

    <h3> שינויים בתנאי השימוש</h3>
    <p>
      עזרן שומרת לעצמה את הזכות לעדכן ולשנות את תנאי השימוש בכל עת, לפי שיקול דעתה הבלעדי. שינויים בתנאי השימוש ייכנסו לתוקף מרגע פרסומם באתר עזרן. המשך השימוש במערכת לאחר פרסום השינויים מהווה הסכמה לתנאים המעודכנים.
    </p>

    <h3>7. קבלת התנאים</h3>
    <p>
      השימוש במערכת עזרן מהווה אישור והסכמה מצדכם לכל התנאים המפורטים לעיל.
      <br />
      אם אינכם מסכימים לאחד או יותר מתנאים אלה, עליכם להפסיק להשתמש במערכת עזרן לאלתר.
    </p>
  </div>
  );
};

export default ServiceTerms;
