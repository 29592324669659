import React, { useState, useEffect, useRef } from 'react';
import './VoiceAiRouterForBusiness.css'; // ייבוא קובץ ה-CSS
import Cookies from 'js-cookie';

function VoiceAiRouterForBusiness() {
  const [businessName, setBusinessName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const queryParams = new URLSearchParams(window.location.search);
  const [agentPhoneNumber, setAgentPhoneNumber] = useState(queryParams.get('agentPhoneNumber'));
  const [agentName, setAgentName] = useState(queryParams.get('agentName'));
  const [managerPhoneNumber, setManagerPhoneNumber] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  const [openingSentence, setOpeningSentence] = useState('');
  const [information, setInformation] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [receiveSummaries, setReceiveSummaries] = useState(false);
  const [receiveUpdates, setReceiveUpdates] = useState(false);
  const [isFirstPart, setIsFirstPart] = useState(true);
  const [loading, setLoading] = useState(false);
  const [useAdditionalInfo, setUseAdditionalInfo] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  // const domain = 'https://8c57-164-138-125-85.ngrok-free.app';
  const domain='https://server.hazran.online'
  // const domain = 'http://192.168.1.195:5000';
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [nameToContact, setNameToContact] = useState('');
  const [phoneToContact, setPhoneToContact] = useState('');
  const [showContactButton, setShowContactButton] = useState(false);

  const inactivityTimer = useRef(null);

  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);
    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);

  const DEFAULT_OPENING_SENTENCE_START = "שלום, הִגַּעְתָּ לְ,  ";
  const DEFAULT_OPENING_SENTENCE = ", אני נציג התמיכה הווירטואלי, אשמח לעזור, אתה יכול לשאול אותי על שעות פתיחה, שרותים, לבקש ממני לשלוח הודעה ועוד, מומלץ ללחוץ על כוכבית בסיום השאלה, זה עוזר לי להבין מתי לענות,  אז במה אני יכול לעזור?"

  useEffect(() => {
    setOpeningSentence(DEFAULT_OPENING_SENTENCE_START + businessName + DEFAULT_OPENING_SENTENCE);
  }, [businessName]);

  useEffect(() => {
    setPhoneNumber(managerPhoneNumber);
  }, [managerPhoneNumber]);

  const resetInactivityTimer = () => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    setShowContactButton(false);
    inactivityTimer.current = setTimeout(() => {
      setShowContactButton(true);
    }, 10000); // 10 שניות של חוסר פעילות
  };

  useEffect(() => {
    // מאזין לשינויים בכל שדה קלט שצריך לעקוב אחריהם
    resetInactivityTimer();
  }, [businessName, managerPhoneNumber, managerEmail, openingSentence, information, additionalInfo, nameToContact, phoneToContact]);

  const handleFirstPartSubmit = (e) => {
    e.preventDefault();

    if (businessName && managerPhoneNumber && managerEmail) {
      generateVerificationCode(managerPhoneNumber);
    } else {
      alert('אנא מלא את כל השדות');
    }
  };

  const generateVerificationCode = async (phone) => {
    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/generate_new/${phone}`);
      const result = await response.json();
      if (result.success) {
        setIsFirstPart(false);
        alert('קוד האימות מחכה לך בטלפון של עזרן 033-765432 ');
      } else {
        alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
    }
  };

  const handleContactButtonClick = () => {
    setIsContactModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsContactModalOpen(false);
  };

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();

    // בדיקת תקינות מספר הטלפון
    if (!isValidPhoneNumber(phoneToContact)) {
        alert('מספר הטלפון שהוזן אינו תקין. אנא בדוק ונסה שוב.');
        return;
    }

    // אם המספר תקין, נמשיך לטפל בפרטים
    const data = {
      agent_phone: agentPhoneNumber,
      customer_phone: phoneToContact,
      service: "business_service_representative",
      message: "אנא חזרו אלי טלפונית",
      customer_name: nameToContact
  };

  try {
      // שליחת הנתונים ב-POST
      const response = await fetch(`${domain}/site_api/customer_inquiry_to_agent`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      });

      if (response.ok) {
          // הודעת הצלחה
          alert(`הפרטים נשלחו בהצלחה!\nשם: ${nameToContact}\nטלפון: ${phoneToContact}`);
      } else {
          // הודעת כישלון
          alert('הייתה בעיה בשליחת הפרטים. אנא נסה שוב.');
      }
  } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה בשליחת הפרטים. אנא נסה שוב.');
  }

  handleCloseModal();
};

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/verify/${managerPhoneNumber}/${verificationCode}`);
      const result = await response.json();
      if (result.success) {
        setVerificationToken(result.token);
        setIsVerified(true);
        alert('הטלפון אומת בהצלחה');
        await checkBusinessData(managerPhoneNumber, result.token);
        Cookies.set('phoneNumber', managerPhoneNumber);
        Cookies.set('managerEmail', managerEmail);
        Cookies.set('verificationToken', result.token);

        Cookies.set('service', " שרותי נתב טלפוני חכם");
        Cookies.set('amount', "100");
        Cookies.set('type', "חודשי");
        Cookies.set('remarks', "השרות כולל עד 100 דקות שיחה, בפעימות של דקה , כל תוספת תגבה לפי חשבון של שקל לפעימה");

      } else {
        alert('קוד האימות אינו נכון, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה באימות הטלפון, נסה שוב');
    }
  };

  const checkBusinessData = async (phone, token) => {
    try {
      const response = await fetch(`${domain}/site_api/voice_ai_router_for_business/get_business_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ token, forwarded_phone: phone })
      });

      const result = await response.json();
      if (result.success) {
        alert('למספר זה כבר קיים נציג שרות במערכת, לא ניתן לפתוח עליו נציג חדש, ניתן לערוך נציג קיים או לבחור מספר אחר');
        setIsFirstPart(true);
      }
    } catch (error) {
      console.error('Error checking business data:', error);
      alert('הייתה בעיה בבדיקת פרטי העסק, נסה שוב');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!receiveUpdates) {
      alert('יש לאשר קבלת עדכונים לאימייל ולטלפון');
      return;
    }

    if (information.length < 200) {
      alert('פרטי העסק חייבים להיות לפחות 200 תווים');
      return;
    }
    if (information.length > 5000) {
      alert('אני מוגבל למקסימום 5000 תווים');
      return;
    }

    const businessData = {
      business_name: businessName,
      forwarded_phone: phoneNumber,
      manager_phone: managerPhoneNumber,
      manager_email: managerEmail,
      opening_sentence: openingSentence,
      information,
      additional_info: useAdditionalInfo ? additionalInfo : '',
      receive_summaries: receiveSummaries,
      receive_updates: receiveUpdates,
      token: verificationToken,
      agent_phone_number: agentPhoneNumber
    };

    setLoading(true);

    try {
      const response = await fetch(`${domain}/site_api/voice_ai_router_for_business/insert_new_business_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(businessData)
      });

      setLoading(false);
      let result
      if (response.ok) {
        result = await response.json();
        if (result.success) {
          // alert('הנתב הוגדר, יש להשלים פרטי תשלום לצורך הפעלה');
          alert('הנתב הוגדר, שיחות שיופנו מהמספר שלך אל עזרן -033-765432- יתקבלו ויענו על ידי הנציג שלך, יש ליצור הפעלת שיחות מהטלפון שלך, לצורך ההפעלה');
          // window.location.href = '/VoiceAiRouterForBusiness/menu';
          window.location.href = 'https://meshulam.co.il/purchase?b=2629441ccbd6b5db69675d03b18be96c'
          // window.location.href = "/getCredit";

          // כאן יש להוסיף את הלוגיקה למעבר לדף התשלום
        } else {
          alert('!הייתה בעיה בהגדרת הנתב, נסה שוב');
        }
      } else {
        result = await response.json();
        if (result.message === "Invalid token") {
          alert('ה-token אינו תקף, יש לבצע אימות מחדש');
          setIsFirstPart(true);
          setIsVerified(false);
          setVerificationToken('');
        } else {
          console.error('Error:', response.statusText);
          alert('.הייתה בעיה בהגדרת הנתב, נסה שוב');
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      alert('הייתה בעיה בהגדרת הנתב, נסה שוב');
    }
  };
  function isValidPhoneNumber(phoneNumber) {
    // מסיר רווחים מהמספר
    phoneNumber = phoneNumber.replace(/\s+/g, '');
  
    // ביטוי רגולרי לבדיקה אם המספר תקין
    const phoneRegex = /^(\+972|0)([23489]|5[0-9]|7[2-9])(\d{7})$/;
  
    return phoneRegex.test(phoneNumber);
  }
  return (
    <div className="form-container">
      {showContactButton  && agentPhoneNumber && isValidPhoneNumber(agentPhoneNumber) && (
        <button className="contact-us-button" onClick={handleContactButtonClick}>
          <img src="/contact_us_1.jpg" alt="צור קשר" />
        </button>
      )}
      <h1>
        מענה קולי חכם לעסקים
      </h1>
      {isFirstPart ? (
        <form onSubmit={handleFirstPartSubmit}>
          <div className="form-group">
            <label>שם העסק:</label>
            <input
              type="text"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>טלפון לניהול הממשק:</label>
            <input
              type="text"
              value={managerPhoneNumber}
              onChange={(e) => setManagerPhoneNumber(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>אימייל מנהל:</label>
            <input
              type="email"
              value={managerEmail}
              onChange={(e) => setManagerEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit">אמת את מספר הטלפון והמשך</button>
          </div>
        </form>
      ) : !isVerified ? (
        <form onSubmit={handleVerificationSubmit}>
          <div className="form-group">
            <label>אנא הזן את קוד האימות המתקבל בשיחה לעזרן 033765432:</label>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <button type="submit">שלח</button>
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>משפט פתיחה:</label>
            <textarea
              rows="2"
              value={openingSentence}
              onChange={(e) => setOpeningSentence(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>ספר לי על העסק, שים לב אני אדע לענות לאנשים רק לפי מה שתאמר לי, לכן , אנא כתוב כמה שיותר פרטים!</label>
            <textarea
              rows="10"
              value={information}
              onChange={(e) => setInformation(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={useAdditionalInfo}
                onChange={(e) => setUseAdditionalInfo(e.target.checked)}
              />
              מעוניין לספק API לפרטים משתנים, כגון מלאי:
            </label>
            {useAdditionalInfo && (
              <input
                type="text"
                value={additionalInfo}
                onChange={(e) => setAdditionalInfo(e.target.value)}
                required
              />
            )}
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={receiveSummaries}
                onChange={(e) => setReceiveSummaries(e.target.checked)}
              />
              האם ברצונך לקבל סיכומי שיחות לאימייל?
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={receiveUpdates}
                onChange={(e) => setReceiveUpdates(e.target.checked)}
                required
              />
              אני מאשר לקבל עדכונים לאימייל ולטלפון
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                required
              />
              אני מאשר את תנאי השימוש המשתנים של עזרן, הנמצאים בנתב הטלפוני           </label>
          </div>
          <div className="form-group">
            <button type="submit">צור נציג!, ועבור לתשלום</button>
          </div>
          {loading && <div className="spinner"></div>}
        </form>
      )}

      {isContactModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <h2>רוצה עזרה?</h2>
            <p>השאר פרטים והסוכן שלך יחזור אליך</p>
            <form onSubmit={handleContactFormSubmit}>
              <div className="form-group">
                <label htmlFor="nameToContact">שם:</label>
                <input
                  type="text"
                  id="nameToContact"
                  value={nameToContact}
                  onChange={(e) => setNameToContact(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="phoneToContact">טלפון:</label>
                <input
                  type="tel"
                  id="phoneToContact"
                  value={phoneToContact}
                  onChange={(e) => setPhoneToContact(e.target.value)}
                  required
                />
              </div>

              <p className="agent-info">או התקשר ישירות לסוכן</p>
              {agentName && <p className="agent-info"><strong>שם הסוכן:</strong>{agentName}</p>}
              <p className="agent-info"><strong>מספר טלפון:</strong> {agentPhoneNumber}</p>
              <div className="form-actions">
                <button type="submit">שלח</button>
                <button type="button" onClick={handleCloseModal}>סגור</button>
              </div>

            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default VoiceAiRouterForBusiness;
