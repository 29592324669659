import React, {useEffect} from 'react';
import './AdvertisingPrices.css'; // מניח שהקובץ CSS הקיים מתאים גם לעמוד זה

const ThankYouPage = () => {

    useEffect(() => {
        document.body.style.backgroundImage = 'url("/background.jpg")';
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';
    
        // יצירת הקישור דינמית
        const link = document.createElement('a');
        link.href = 'https://hazran.online';
        link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
        link.style.display = 'block';
        link.style.textAlign = 'center';
        link.style.marginTop = '20px';
        link.style.color = '#ffffff';
        link.style.textDecoration = 'none';
        link.style.fontSize = '14px';
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
    
        // הוספת הקישור לגוף הדף
        document.body.appendChild(link);
    
        // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
        return () => {
          document.body.style.backgroundImage = '';
          document.body.style.backgroundSize = '';
          document.body.style.backgroundPosition = '';
          document.body.removeChild(link);
        };
      }, []);
    

  return (
    <div className="container">
      <h1 className="header-title">תודה שרכשת את שירותינו </h1>
      עזרן, מבית מוטו
      <p>
         <a href="/">לחזרה לדף הבית, לחץ כאן</a>
      </p>
    </div>
  );
};

export default ThankYouPage;
