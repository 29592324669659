import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './Registration.css'; // ייבוא קובץ ה-CSS

function FreeArenaRegistration() {
  const [managerPhoneNumber, setManagerPhoneNumber] = useState('');
  const [target_url, setTarget_url] = useState('https://');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCodeInputBox, setShowCodeInputBox] = useState(false);
  const domain = 'https://server.hazran.online';
  // const domain = 'http://localhost:5000';

  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

  

    const savedPhoneNumber = Cookies.get('managerPhoneNumber');
    const savedToken = Cookies.get('verificationToken');
    
    if (savedPhoneNumber && savedToken && isValidToken(savedToken)) {
      setManagerPhoneNumber(savedPhoneNumber);
      setVerificationToken(savedToken);
      setIsVerified(true);
      
    }

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);

  const isValidToken = (token) => {
    const expiry = parseInt(token.slice(-10), 10);
    const now = Math.floor(Date.now() / 1000);
    return now < expiry;
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (managerPhoneNumber) {
      generateVerificationCode(managerPhoneNumber);
      setShowCodeInputBox(true);
    } else {
      alert('אנא מלא את מספר הטלפון');
    }
  };

  const generateVerificationCode = async (phone) => {
    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/generate_new/${phone}`);
      const result = await response.json();
      if (result.success) {
        alert('קוד האימות מחכה לך בעזרן, בטלפון 033-765432');
      } else {
        alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/verify/${managerPhoneNumber}/${verificationCode}`);
      const result = await response.json();
      if (result.success) {
        const token = result.token;
        setVerificationToken(token);
        setIsVerified(true);
        // Cookies.set('managerPhoneNumber', managerPhoneNumber, { expires: 1/24 });
        // Cookies.set('verificationToken', token, { expires: 1/24 });
        // setShowCodeInputBox(false)
        alert('הטלפון אומת בהצלחה');
      } else {
        alert('קוד האימות אינו נכון, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה באימות הטלפון, נסה שוב');
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${domain}/site_api/free_arena/service_registration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          manager_phone: managerPhoneNumber,
          target_url: target_url,
          token: verificationToken
        })
      });

      setLoading(false);
      let result;
      if (response.ok) {
        result = await response.json();
        if (result.success) {
          alert('הנתב עודכן בהצלחה');
        } else {
          let alertMessage="הייתה בעיה בעדכון הנתב, נסה שוב. "
          if (result.message){alertMessage+="הודעת השגיאה היא:  "+result.message}
          alert(alertMessage);
        }
      } else {
        result = await response.json();
        if (result.message === 'Invalid token') {
          alert('ה-token אינו תקף, יש לבצע אימות מחדש');
          setIsVerified(false);
          setVerificationToken('');
        } else {
          console.error('Error:', response.statusText);
          let alertMessage="הייתה בעיה בעדכון הנתב, נסה שוב. "
          if (result.message){alertMessage+="הודעת השגיאה היא:  "+result.message}
          alert(alertMessage);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      alert('הייתה בעיה בעדכון הנתב, נסה שוב');
    }
  };

  return (
    <div className="form-container">
      <h1>הזירה החופשית -רישום שירות</h1>
      {!isVerified ? (
        <>
          <form onSubmit={handlePhoneSubmit}>
            <div className="form-group">
              <label>טלפון לניהול הממשק:</label>
              <input 
                type="text" 
                value={managerPhoneNumber} 
                onChange={(e) => setManagerPhoneNumber(e.target.value)} 
                required 
              />
            </div>
            <div className="form-group">
              <button type="submit">אמת את מספר הטלפון והמשך</button>
            </div>
          </form>
          {showCodeInputBox && (
            <form onSubmit={handleVerificationSubmit}>
              <div className="form-group">
                <label>אנא הזן את קוד האימות המתקבל בשיחה לעזרן 033765432:</label>
                <input 
                  type="text" 
                  value={verificationCode} 
                  onChange={(e) => setVerificationCode(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <button type="submit">שלח</button>
              </div>
            </form>
          )}
        </>
      ):


      <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>אנא הזן את ה URL של השירות, נתב זה ישמש לרישום השירות ולכל התקשורת עם השרת המנהל </label>
                <input 
                  type="text" 
                  value={target_url} 
                  onChange={(e) => setTarget_url(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <button type="submit">שלח, שליחת הטופס היא הסכמה לתנאי השימוש</button>
              </div>
            </form>
      }
      
    </div>
  );
}

export default FreeArenaRegistration;
