import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './Registration.css'; // ייבוא קובץ ה-CSS

function CompetitionRegistration() {
  const [RouteId, setRouteId] = useState('');
  const [loading, setLoading] = useState(false);
  const domain = 'https://server.hazran.online';
  // const domain = 'http://localhost:5000';

  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);


  const isValidRouteId=(routeId)=> {
    const regex = /^\d{6}$/;
    return regex.test(routeId);
}

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isValidRouteId(RouteId)){
      alert("קוד שירות אינו תקין, קוד השירות הינו הנתב לשירות שלך, והוא נשלח אליך לאימייל עם רישום השירות")
    }
    else{
    try {
      const response = await fetch(`${domain}/site_api/free_arena/competition_registration`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          route_id: RouteId
        })
      });

      setLoading(false);
      let result;
      if (response.ok) {
        result = await response.json();
        if (result.success) {
          alert('ההרשמה לתחרות בוצעה בהצלחה');
        } else {
          alert('הייתה בעיה בעדכון, לא בוצע רישום, נסה שוב');
        }
      } else {
        result = await response.json();
          console.error('Error:', response.statusText);
          alert('הייתה בעיה, אנא נסה שוב');
        
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      alert('הייתה בעיה ברישום, נסה שוב');
    }}
  };

  return (
    <div className="form-container">
      <h1>הזירה החופשית -רישום לתחרות השירותים</h1>
      <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>אנא הזן את קוד השירות </label>
                <input 
                  type="text" 
                  value={RouteId} 
                  onChange={(e) => setRouteId(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <button type="submit">שלח, שליחת הטופס היא הסכמה לתנאי השימוש</button>
                <br/>
               <strong>יש לרשום את השירות ולהפעיל אותו לפני הרישום לתחרות.<br/>
               <a href='/FreeArena/registration'>למעבר לדף הרישום לחץ כאן</a><br/>
               </strong> 
                  התחרות (והמערכת בכללה), מיועדת לציבור החרדי וכל השירותים צריכים להיות מותאמים לכך.
                השתתפות בתחרות מיועדת לשירותים חינמיים, שאינם גובים תשלום לא דרך המערכת ולא בצורה חיצונית.
                בחירת השרות המנצח תעשה בהתאם למספר המשתמשים בה ולשביעות רצון המשתמשים.
                התחרות מותנת במינימום משתתפים ומוגבלת במספר המשתתפים.
                זכות המערכת להגביל או לגרוע את אחד המשתתפים\השירותים לפי שיקול שעתה, ללא צורך לתת הסברים וללא זכות ערעור או תביעה כל שהיא בגין כך.
                המערכת אינה אחראית לכל נזק או הפסד שיגרם למשתתפים או למשתתמשים על ידה.
              מוסכם כי ביה"ד הישר והטוב ירושלים, הינו הגורם הבלעדי המוסמך לשיפוט.
              </div>
            </form>
            
      
    </div>
  );
}

export default CompetitionRegistration;
