import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './VoiceAiRouterForBusiness.css'; // ייבוא קובץ ה-CSS

function EditVoiceAiRouterForBusiness() {
  const [managerPhoneNumber, setManagerPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [businessData, setBusinessData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCodeInputBox, setShowCodeInputBox] = useState(false);
  const domain = 'https://server.hazran.online';
  // const domain = 'http://localhost:5000';

  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);
    const savedPhoneNumber = Cookies.get('managerPhoneNumber');
    const savedToken = Cookies.get('verificationToken');
    
    if (savedPhoneNumber && savedToken && isValidToken(savedToken)) {
      setManagerPhoneNumber(savedPhoneNumber);
      setVerificationToken(savedToken);
      setIsVerified(true);
      fetchBusinessData(savedPhoneNumber, savedToken);
    }

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);

  const isValidToken = (token) => {
    const expiry = parseInt(token.slice(-10), 10);
    const now = Math.floor(Date.now() / 1000);
    return now < expiry;
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (managerPhoneNumber) {
      generateVerificationCode(managerPhoneNumber);
      setShowCodeInputBox(true);
    } else {
      alert('אנא מלא את מספר הטלפון');
    }
  };

  const generateVerificationCode = async (phone) => {
    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/generate_new/${phone}`);
      const result = await response.json();
      if (result.success) {
        alert('קוד האימות מחכה לך בעזרן, בטלפון 033-765432');
      } else {
        alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/verify/${managerPhoneNumber}/${verificationCode}`);
      const result = await response.json();
      if (result.success) {
        const token = result.token;
        setVerificationToken(token);
        setIsVerified(true);
        Cookies.set('managerPhoneNumber', managerPhoneNumber, { expires: 1/24 });
        Cookies.set('verificationToken', token, { expires: 1/24 });
        alert('הטלפון אומת בהצלחה');
        await fetchBusinessData(managerPhoneNumber, token);
      } else {
        alert('קוד האימות אינו נכון, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה באימות הטלפון, נסה שוב');
    }
  };

  const fetchBusinessData = async (phone, token) => {
    setLoading(true);
    try {
      const response = await fetch(`${domain}/site_api/voice_ai_router_for_business/get_business_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ token, forwarded_phone: phone })
      });

      const result = await response.json();
      if (result.success) {
        setBusinessData(result);
      } else {
        alert('הייתה בעיה בשליפת פרטי העסק, נסה שוב, שים לב עמוד זה מיועד לעדכון פרטי נציג ולא ליצירת נציג חדש');
        Cookies.remove('managerPhoneNumber');
        Cookies.remove('verificationToken');
        setIsVerified(false);
        setManagerPhoneNumber('');
        setVerificationToken('');
        }
    } catch (error) {
      console.error('Error fetching business data:', error);
      alert('הייתה בעיה בשליפת פרטי העסק, נסה שוב');
      Cookies.remove('managerPhoneNumber');
      Cookies.remove('verificationToken');
      setIsVerified(false);
      setManagerPhoneNumber('');
      setVerificationToken('');
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!businessData.receive_updates) {
      alert('יש לאשר קבלת עדכונים לאימייל ולטלפון');
      return;
    }

    if (businessData.information.length < 200) {
      alert('פרטי העסק חייבים להיות לפחות 200 תווים');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${domain}/site_api/voice_ai_router_for_business/update_business_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...businessData,
          forwarded_phone: businessData.forwarded_from_phone_number,
          token: verificationToken
        })
      });

      setLoading(false);
      let result;
      if (response.ok) {
        result = await response.json();
        if (result.success) {
          alert('הנתב עודכן בהצלחה');
        } else {
          alert('הייתה בעיה בעדכון הנתב, נסה שוב');
        }
      } else {
        result = await response.json();
        if (result.message === 'Invalid token') {
          alert('ה-token אינו תקף, יש לבצע אימות מחדש');
          setIsVerified(false);
          setVerificationToken('');
        } else {
          console.error('Error:', response.statusText);
          alert('הייתה בעיה בעדכון הנתב, נסה שוב');
        }
      }
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
      alert('הייתה בעיה בעדכון הנתב, נסה שוב');
    }
  };

  return (
    <div className="form-container">
      <h1>עריכת נציג מענה קולי חכם לעסקים</h1>
      {!isVerified && (
        <>
          <form onSubmit={handlePhoneSubmit}>
            <div className="form-group">
              <label>טלפון לניהול הממשק:</label>
              <input 
                type="text" 
                value={managerPhoneNumber} 
                onChange={(e) => setManagerPhoneNumber(e.target.value)} 
                required 
              />
            </div>
            <div className="form-group">
              <button type="submit">אמת את מספר הטלפון והמשך</button>
            </div>
          </form>
          {showCodeInputBox && (
            <form onSubmit={handleVerificationSubmit}>
              <div className="form-group">
                <label>אנא הזן את קוד האימות המתקבל בשיחה לעזרן 033765432:</label>
                <input 
                  type="text" 
                  value={verificationCode} 
                  onChange={(e) => setVerificationCode(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <button type="submit">שלח</button>
              </div>
            </form>
          )}
        </>
      )}
      {businessData && (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>שם העסק:</label>
            <input 
              type="text" 
              value={businessData.business_name} 
              onChange={(e) => setBusinessData({ ...businessData, business_name: e.target.value })} 
              required 
            />
          </div>
          <div className="form-group">
            <label>משפט פתיחה:</label>
            <textarea 
              rows="2" 
              value={businessData.opening_sentence} 
              onChange={(e) => setBusinessData({ ...businessData, opening_sentence: e.target.value })} 
              required 
            />
          </div>
          <div className="form-group">
            <label>ספר לי על העסק, שים לב אני אדע לענות לאנשים רק לפי מה שתאמר לי, לכן , אנא כתוב כמה שיותר פרטים!</label>
            <textarea 
              rows="10" 
              value={businessData.information} 
              onChange={(e) => setBusinessData({ ...businessData, information: e.target.value })} 
              required 
            />
          </div>
          <div className="form-group">
            <label>
              <input 
                type="checkbox" 
                checked={!!businessData.changing_information_link} 
                onChange={(e) => setBusinessData({ ...businessData, changing_information_link: e.target.checked ? businessData.changing_information_link : '' })} 
              />
              מעוניין לספק API לפרטים משתנים, כגון מלאי:
            </label>
            {!!businessData.changing_information_link && (
              <input 
                type="text" 
                value={businessData.changing_information_link} 
                onChange={(e) => setBusinessData({ ...businessData, changing_information_link: e.target.value })} 
                required 
              />
            )}
          </div>
          <div className="form-group">
            <label>
              <input 
                type="checkbox" 
                checked={businessData.receive_summaries} 
                onChange={(e) => setBusinessData({ ...businessData, receive_summaries: e.target.checked })} 
              />
              האם ברצונך לקבל סיכומי שיחות לאימייל?
            </label>
          </div>
          <div className="form-group">
            <label>
              <input 
                type="checkbox" 
                checked={businessData.receive_updates} 
                onChange={(e) => setBusinessData({ ...businessData, receive_updates: e.target.checked })} 
                required 
              />
              אני מאשר לקבל עדכונים לאימייל ולטלפון
            </label>
          </div>
          <div className="form-group">
            <button type="submit">עדכן את הנציג!</button>
          </div>
          {loading && <div className="spinner"></div>}
          

        </form>
        
      )}
      {isVerified &&( <div className="form-group"><br /><button onClick={() => {
        Cookies.remove('managerPhoneNumber');
        Cookies.remove('verificationToken');
        window.location.href = '/';
      }}>יציאה</button></div>)}
    </div>
  );
}

export default EditVoiceAiRouterForBusiness;
