import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const ProductsExample = () => {
        const code = `
        from flask import Flask, jsonify, request
        import json

        app = Flask(__name__)


        manager_phone="....."  # הכנס את הטלפון שלך, טלפון  זה ישמש לניהול השירות
        email="....."  # הכנס את האימייל שלך
        base_url="....."

        @app.route('/', methods=['GET'])
        def welcome():
            return "ברוך הבא", 200


        @app.route('/ordering_products', methods=['POST'])
        def ordering_products():
            target = request.json.get('target')
            if target == 'registration':
                # חלק הרישום של השירות
                service_data = {
                    'manager_phone': manager_phone,  # מספר טלפון של מנהל השירות
                    'email': email,
                    'connection_url': base_url+"ordering_products",
                    'service_name': 'הזמנת מוצרים',  
                    'brief_description': 'שרות זה מאפשר להזמין מוצרים בצורה טלפונית ישירות אליך לבית',  
                    'message': 'אנא אמור את כל המוצרים שאתה מעוניין להזמין', 
                    'audio_url':'',
                    'long_explanation': 'כל המוצרים שיוזמנו במערכת מגיעים מרשת יש חסד ובמחירים של יש חסד, בתוספת 20 ש"ח עבר המשלוח', 
                    'required_data_schema': {'list_of_consumer_products_the_customer_wants_to_order_including_number_and_conditions':'list'},  # אין צורך במבנה נתונים מורכב
                    'number_of_digits': 0,  # אנחנו לא מצפים ללחיצה 
                    'phone_number_required': True,  # יש צורך במספר טלפון מהמשתמש
                    'email_required': False,  # אין צורך בדוא"ל מהמשתמש
                    'credit_card_required': False,  # אין צורך בתשלום
                    'system_payments': False,  # השירות לא דורש תשלומים דרך המערכת
                    'external_payments': True,  # השירות דורש תשלומים חיצוניים
                    'entry_amount_to_be_paid': 0,  # ללא תשלום
                    'referral_phone': '',  # מספר טלפון להפניה ישירה לשרות
                    'analysis_delay': 1,
                    'returned_data':json.dumps({"next":"address"})
                }
                return jsonify(service_data), 200
            
            elif target == 'service_processing':
                try:
                    returned_data=json.loads(request.json.get('returned_data'))
                except:
                    returned_data=dict()
                
                if returned_data.get('next',None)=="address":
                    returned_data={"next":"end", "phone":request.json.get('phone_number'), 'products_response_url': request.json.get('analysis_response_url')}
                    return jsonify({
                    'message': "תודה על הזמנתך, אנא אמור את כתובת מגורך המלאה, כולל עיר, רחוב ומספר",
                    'required_data_schema':{'full_residential_address':'str'},
                    'returned_data':json.dumps(returned_data)
                }), 200
                elif returned_data.get('next',None)=="end":
                    returned_data['next']="email_and_exit"
                    returned_data['address_response_url']=request.json.get('analysis_response_url')
                    return jsonify({
                    'message': "תודה על הזמנתך, אנו ניצור עימך קשר בהקדם",
                    'returned_data':json.dumps(returned_data)
                    }), 200
                else:
                    phone=returned_data.get("phone")
                    if phone:
                        email_content=f"""
                                        קיבלת הזמנה מאת {phone}
                                        ניתן לצפות בפרטי ההזמנה בקישור https://jsonviewer.stack.hu/#{returned_data.get('products_response_url')}
                                        ניתן לצפות בפרטי הכתובת בקישור https://jsonviewer.stack.hu/#{returned_data.get('address_response_url')}
                                        מומלץ ללחוץ על Viewer להצגה מיטבית

                                            """
                        return jsonify({'email_me_on_service_end_data':email_content}), 200 # שליחת אימייל וסיום השירות
                    return jsonify({}), 200 # החזר ריק הוא הסימן לסיום השירות





            return jsonify({'error': 'Invalid target'}), 200



        if __name__ == '__main__':
            app.run(host='0.0.0.0', port=5000)
  
        
        `;

    
const codeBlockStyle = {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    };
  return (
    <div dir="ltr" style={codeBlockStyle}>
  <SyntaxHighlighter language="javascript" style={dark} customStyle={{ backgroundColor: '#000', color: '#fff' }} >
  {code}
   </SyntaxHighlighter></div>
);
};

export default ProductsExample;
