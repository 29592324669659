import React, { useState, useEffect } from 'react';

function CrudeJobEntry() {
    const [jobPost, setJobPost] = useState('');
    const [password, setPassword] = useState('');
    useEffect(() => {
        // הגדרת רקע לכל העמוד
        document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '100px',
  };

  const textAreaStyle = {
    margin: '10px 0',
    width: '300px', // רוחב גדול יותר לתיבת הקלט
    height: '150px', // גובה גדול יותר לתיבת הקלט
  };

  const passwordInputStyle = {
    margin: '10px 0',
    width: '200px', // רוחב קטן יותר לתיבת הסיסמה
  };

  const buttonStyle = {
    cursor: 'pointer',
    padding: '10px 20px',
    fontSize: '16px',
    margin: '10px 0',
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!jobPost || !password) {
      alert('נא למלא את כל השדות');
      return;
    }

    const postData = {
      job_post: jobPost,
      password: password,
    };

    try {
      const response = await fetch('https://server.hazran.online/job_search/crude_job_entry', {
        // const response = await fetch('https://bcd0-164-138-127-246.ngrok-free.app/job_search/crude_job_entry', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
      alert(' שגיאה בשליחת הנתונים:' + response.status);
      throw new Error(`HTTP error! status: ${response.status}`);
      }

      // const data = await response.json();
      const data = response.text;
      console.log(data);

      setJobPost(''); // איתחול תיבת הקלט לטקסט
      alert('נתונים נשלחו בהצלחה');
    } catch (error) {
      console.error('שגיאה בשליחת הנתונים:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={formStyle}>
      <div>
        <textarea
          style={textAreaStyle}
          placeholder="The job description, enter all the details here, don't forget contact methods, you must enter one post, one job, each time."
          value={jobPost}
          onChange={(e) => setJobPost(e.target.value)}
        />
      </div>
      <div>
        <input
          type="password"
          style={passwordInputStyle}
          placeholder="סיסמה..."
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit" style={buttonStyle}>שלח</button>
    </form>
  );
}

export default CrudeJobEntry;
