import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './VoiceAiRouterForBusiness.css'; // ייבוא קובץ ה-CSS

function CallTracking() {
  const [managerPhoneNumber, setManagerPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationToken, setVerificationToken] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [businessData, setBusinessData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCodeInputBox, setShowCodeInputBox] = useState(false);
  const domain = 'https://server.hazran.online';
  // const domain = 'http://localhost:5000';

  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);
    const savedPhoneNumber = Cookies.get('managerPhoneNumber');
    const savedToken = Cookies.get('verificationToken');
    
    if (savedPhoneNumber && savedToken && isValidToken(savedToken)) {
      setManagerPhoneNumber(savedPhoneNumber);
      setVerificationToken(savedToken);
      setIsVerified(true);
      fetchCallTracking(savedPhoneNumber, savedToken);
      document.body.removeChild(link);
    }

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
    };
  }, []);

  const isValidToken = (token) => {
    const expiry = parseInt(token.slice(-10), 10);
    const now = Math.floor(Date.now() / 1000);
    return now < expiry;
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (managerPhoneNumber) {
      generateVerificationCode(managerPhoneNumber);
      setShowCodeInputBox(true);
    } else {
      alert('אנא מלא את מספר הטלפון');
    }
  };

  const generateVerificationCode = async (phone) => {
    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/generate_new/${phone}`);
      const result = await response.json();
      if (result.success) {
        alert('קוד האימות מחכה לך בעזרן, בטלפון 033-765432');
      } else {
        alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה בשליחת קוד האימות, נסה שוב');
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${domain}/site_api/phone_verification_code/verify/${managerPhoneNumber}/${verificationCode}`);
      const result = await response.json();
      if (result.success) {
        const token = result.token;
        setVerificationToken(token);
        setIsVerified(true);
        Cookies.set('managerPhoneNumber', managerPhoneNumber, { expires: 1/24 });
        Cookies.set('verificationToken', token, { expires: 1/24 });
        alert('הטלפון אומת בהצלחה');
        await fetchCallTracking(managerPhoneNumber, token);
      } else {
        alert('קוד האימות אינו נכון, נסה שוב');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('הייתה בעיה באימות הטלפון, נסה שוב');
    }
  };

  const fetchCallTracking = async (phone, token) => {
    setLoading(true);
    try {
      const response = await fetch(`${domain}/site_api/voice_ai_router_for_business/get_this_month_forwarded_counter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ token, phone: phone })
      });

      const result = await response.json();
      if (result.success) {
        setBusinessData(result.representative_counter);
      } else {
        alert('הייתה בעיה בשליפת פרטי העסק, נסה שוב, שים לב עמוד זה מיועד למעקב אחר נציג קיים');
      }
    } catch (error) {
      console.error('Error fetching business data:', error);
      alert('הייתה בעיה בשליפת פרטי העסק, נסה שוב');
      Cookies.remove('managerPhoneNumber');
      Cookies.remove('verificationToken');
      setIsVerified(false);
      setManagerPhoneNumber('');
      setVerificationToken('');
    }
    setLoading(false);
  };

  return (
    <div className="form-container">
      <h1>מעקב שיחות</h1>
      {!isVerified && (
        <>
          <form onSubmit={handlePhoneSubmit}>
            <div className="form-group">
              <label>טלפון לניהול הממשק:</label>
              <input 
                type="text" 
                value={managerPhoneNumber} 
                onChange={(e) => setManagerPhoneNumber(e.target.value)} 
                required 
              />
            </div>
            <div className="form-group">
              <button type="submit">אמת את מספר הטלפון והמשך</button>
            </div>
          </form>
          {showCodeInputBox && (
            <form onSubmit={handleVerificationSubmit}>
              <div className="form-group">
                <label>אנא הזן את קוד האימות המתקבל בשיחה לעזרן 033765432:</label>
                <input 
                  type="text" 
                  value={verificationCode} 
                  onChange={(e) => setVerificationCode(e.target.value)} 
                  required 
                />
              </div>
              <div className="form-group">
                <button type="submit">שלח</button>
              </div>
            </form>
          )}
        </>
      )}
      {businessData && (
        <div>
          <h2>מונה יחידות: {businessData.units}</h2>
          <table>
            <thead>
              <tr>
                <th>מ</th>
                <th>משך</th>
                <th>יחידות</th>
              </tr>
            </thead>
            <tbody>
              {businessData.list.map((call) => (
                <tr key={call.sid}>
                  <td>{call.from_}</td>
                  <td>{call.duration}</td>
                  <td>{call.units}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {loading && <div className="spinner"></div>}
      {isVerified &&( <div className="form-group"><br /><button onClick={() => {
        Cookies.remove('managerPhoneNumber');
        Cookies.remove('verificationToken');
        window.location.href = '/';
      }}>יציאה</button></div>)}
    </div>
  );
}

export default CallTracking;
