import React from 'react';
import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  WhatsappShareButton, WhatsappIcon,
  LinkedinShareButton, LinkedinIcon,
  TelegramShareButton, TelegramIcon,
  PinterestShareButton, PinterestIcon,
  RedditShareButton, RedditIcon,
  EmailShareButton, EmailIcon
} from 'react-share';

const ShareComponent = () => {
  const shareUrl = window.location.href
  //   const shareUrl = encodeURIComponent(window.location.href)
  const title = "הי, זה קישור לקורות החיים שלי, הכנס ושתף!"
  const shareImage = '/logo256.png';

  return (
    <div>
      <FacebookShareButton url={shareUrl} quote={title}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <WhatsappShareButton url={shareUrl} title={title}>
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <LinkedinShareButton url={shareUrl}>
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
      <TelegramShareButton url={shareUrl} title={title}>
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      <PinterestShareButton url={shareUrl} media={shareImage}>
        <PinterestIcon size={32} round />
      </PinterestShareButton>
      <RedditShareButton url={shareUrl} title={title}>
        <RedditIcon size={32} round />
      </RedditShareButton>
      <EmailShareButton url={shareUrl} subject={title} body="body">
        <EmailIcon size={32} round />
      </EmailShareButton>
    </div>
  );
};

export default ShareComponent;
