import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './getCredit.css';

function PaymentManagement() {
  const [managerPhoneNumber, setManagerPhoneNumber] = useState('');
  const [salesPhoneNumber, setSalesPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [service, setService] = useState('');
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('חד פעמי');
  const [remarks, setRemarks] = useState('');
  const [step, setStep] = useState(1);

const domain = 'https://server.hazran.online';
// const domain = 'http://localhost:5000';


  useEffect(() => {
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    const managerPhoneFromCookie = Cookies.get('managerPhoneNumber');
    const salesPhoneFromCookie = Cookies.get('salesPhoneNumber');
    const emailFromCookie = Cookies.get('email');
    const serviceFromCookie = Cookies.get('service');
    const amountFromCookie = Cookies.get('amount');
    const typeFromCookie = Cookies.get('type');
    const remarksFromCookie = Cookies.get('remarks');

    

    if (managerPhoneFromCookie) setManagerPhoneNumber(managerPhoneFromCookie);
    if (salesPhoneFromCookie) setSalesPhoneNumber(salesPhoneFromCookie);
    if (emailFromCookie) setEmail(emailFromCookie);
    if (serviceFromCookie) setService(serviceFromCookie);
    if (amountFromCookie) setAmount(amountFromCookie);
    if (typeFromCookie) setType(typeFromCookie);
    if (remarksFromCookie) setRemarks(remarksFromCookie);

    if (managerPhoneFromCookie) {
      setStep(2);
    } else if (salesPhoneFromCookie) {
      setStep(3);
    } else {
      setStep(1);
    }

    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);


  const handlePaymentClick = (event) => {
    event.preventDefault(); // מניעת רענון העמוד
    Cookies.set('managerPhoneNumber', managerPhoneNumber);
    Cookies.set('salesPhoneNumber', salesPhoneNumber);
    Cookies.set('email', email);
    Cookies.set('service', service);
    Cookies.set('amount', amount);
    Cookies.set('type', type);
    Cookies.set('remarks', remarks);
    const additionalCharge = Cookies.get('additionalCharge');

    if (additionalCharge === 'true'){
      
      fetch(`${domain}/site_api/payments/insert_additional_charge_by_sales_representativ`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phone: managerPhoneNumber,
          email: email,
          amount: amount,
          service: service,
          type: type,
          remarks: remarks,
          representativ_phone: salesPhoneNumber
        })
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert("השתלום דווח בהצלחה")
          console.log('Charge inserted successfully');
          window.location.reload()
          // navigate('/getCredit');
        } else {
          alert("תקלה בביצוע התשלום, דיווח התקלה: "+data.message)
          console.error('Error inserting charge:', data.message);
        }
      })
      .catch(error => {
          alert("תקלה בביצוע התשלום, דיווח התקלה: "+error)
        console.error('Error:', error);
      });
    } else {
      window.location.href = `/getCredit`
      // navigate('/getCredit');
    }
  };






  const renderInput = (label, value, setValue, className = '', readOnly = false) => (
    <div>
      <label className="get-credit-label">{label}</label>
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={`get-credit-input short-input1  ${className}`}
        readOnly={readOnly}
      />
    </div>
  );

  if (step === 1) {
    return (
      <div className="get-credit-container">
        <form className="get-credit-form">
          <h1 className="get-credit-title">עזרן- נתב בינה מלאכותית טלפוני</h1>
          {renderInput("טלפון נציג מכירות", salesPhoneNumber, setSalesPhoneNumber)}
          <button
            onClick={() => {
              Cookies.set('salesPhoneNumber', salesPhoneNumber);
              setStep(3);
            }}
            className="get-credit-button"
          >
            שלח
          </button>
        </form>
      </div>
    );
  }

  if (step === 2) {
    return (
      <div className="get-credit-container">
        <form className="get-credit-form">
          <h1 className="get-credit-title">עזרן- נתב בינה מלאכותית טלפוני</h1>
          <button
            onClick={() => {
              Object.keys(Cookies.get()).forEach((cookie) => {
                if (cookie !== 'managerPhoneNumber' && cookie !== 'salesPhoneNumber') {
                  Cookies.remove(cookie);
                }
              });
              // setManagerPhoneNumber('')
                // setEmail('')
                setService('')
                setAmount('');
                setType('חד פעמי')
                setRemarks('');
              Cookies.set('additionalCharge', 'true');
              setStep(3);
            }}
            className="get-credit-button"
          >
            הזן תשלום נוסף לאותו משתמש
          </button>
          <button
            onClick={() => {
              Object.keys(Cookies.get()).forEach((cookie) => {
                if (cookie !== 'salesPhoneNumber') {
                  Cookies.remove(cookie);
                }
              });
              setManagerPhoneNumber('')
                setEmail('')
                setService('')
                setAmount('');
                setType('חד פעמי')
                setRemarks('');
              // setManagerPhoneNumber('');
              setStep(3);
            }}
            className="get-credit-button"
          >
            הזן תשלום למשתמש אחר
          </button>
        </form>
      </div>
    );
  }

  if (step === 3) {
    return (
      <div className="get-credit-container">
        <form className="get-credit-form">
          <h1 className="get-credit-title">עזרן- נתב בינה מלאכותית טלפוני</h1>
          
            <div>
              <button
                onClick={() => {
                  setStep(4)
                  setService('חבילת פרסום מנימלית בסך 150 ש"ח');
                  setAmount('150');
                  setType('חד פעמי');
                  setRemarks('השרות כולל 300 הופעות קוליות, מתוזמנות על ידי המערכת, במוקדי האזנה של המשתמש');
                }}
                className="get-credit-button"
              >
                תשלום עבור חבילת פרסום מנימלית בסך 150 ש"ח
              </button>
              <button
                onClick={() => {
                  setStep(4)
                  setService('חבילת פרסום בסיסית בסך 400 ש"ח');
                  setAmount('400');
                  setType('חד פעמי');
                  setRemarks('השרות כולל 1000 הופעות קוליות, מתוזמנות על ידי המערכת, במוקדי האזנה של המשתמש');
                }}
                className="get-credit-button"
              >
                תשלום עבור חבילת פרסום בסיסית בסך 400 ש"ח
              </button>
              <button
                onClick={() => {
                  setStep(4)
                  setService('חבילת פרסום מורחבת בסך 1500 ש"ח');
                  setAmount('1500');
                  setType('חד פעמי');
                  setRemarks('השרות כולל 4500 הופעות קוליות, מתוזמנות על ידי המערכת, במוקדי האזנה של המשתמש');
                }}
                className="get-credit-button"
              >
                תשלום עבור חבילת פרסום מורחבת בסך 1500 ש"ח
              </button>
              <button
                onClick={() => {
                  setStep(4)
                  setService('שרותי נתב טלפוני חכם');
                  setAmount('100');
                  setType('חודשי');
                  setRemarks('השרות כולל עד 100 דקות שיחה, בפעימות של דקה , כל תוספת תגבה לפי חשבון של שקל לפעימה');
                }}
                className="get-credit-button"
              >
                תשלום עבור נציג שרות טלפוני חכם
              </button>
              <button
                onClick={() => {
                  setStep(4)
                  setService('תשלום פתוח');
                }}
                className="get-credit-button"
              >
                תשלום פתוח
              </button>
            </div>
            </form>
            </div>
    )
            }
  if (step === 4) {
              return (
            <div className="get-credit-container">
             <form className="get-credit-form">
       
            <div>
              {renderInput("טלפון בעל העסק", managerPhoneNumber, setManagerPhoneNumber, '', !!Cookies.get('managerPhoneNumber'))}
              {Cookies.get('additionalCharge')!='true' && renderInput("אימייל בעל העסק", email, setEmail, '', !!Cookies.get('email'))}
              {renderInput("שרות", service, setService, '', true)}
              {renderInput("סכום", amount, setAmount, '', !!Cookies.get('amount'))}
              <div>
                <label className="get-credit-label">סוג</label>
                <select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  className="get-credit-input"
                  disabled={!!Cookies.get('type')}
                >
                  <option value="חד פעמי">חד פעמי</option>
                  <option value="חודשי">חודשי</option>
                </select>
              </div>
              {renderInput("הערות", remarks, setRemarks, '', !!Cookies.get('remarks'))}
              <button
                onClick={handlePaymentClick}
                  // (e) => {
                  // e.preventDefault();
                  // Cookies.set('managerPhoneNumber', managerPhoneNumber);
                  // Cookies.set('salesPhoneNumber', salesPhoneNumber);
                  // Cookies.set('email', email);
                  // Cookies.set('service', service);
                  // Cookies.set('amount', amount);
                  // Cookies.set('type', type);
                  // Cookies.set('remarks', remarks);
                  // const additionalCharge = Cookies.get('additionalCharge');
                  // if (additionalCharge === 'true'){
                  //   const domain='https://server.hazran.online'

                  // }else{
                  //   window.location.href = `/getCredit`;
                  // }

                  
                // }}
                className="get-credit-button"
              >
                תשלום
              </button>
            </div>
          
        </form>
      </div>
    );
  }

  return null;
}

export default PaymentManagement;
