import React, { useState, useEffect } from 'react';

function ResumeInput() {
  const [localId, setLocalId] = useState('');

  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);

  const handleChange = (event) => {
    setLocalId(event.target.value);
  };

  const handleSubmit = () => {
    window.location.href = `/resume/displayer/${localId}`;
  };

  // סגנון עבור האלמנט העליון
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto', // במקום 100vh, כדי שהגובה יתאים לתוכן
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // שקיפות חלקית
    borderRadius: '10px',
    padding: '40px', // הגדלת הרווח בפנים
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // מסגרת עם צל עדין
    maxWidth: '400px', // לרספונסיביות
    margin: '150px auto', // ממרכז באופן אוטומטי ומוסיף מרווח מלמעלה ומלמטה
    gap: '20px', // מוסיף רווח בין האלמנטים בפנים
  };

  const inputStyle = {
    padding: '10px',
    margin: '10px 0', // מרווח נוסף מלמעלה ומלמטה לכל תיבת קלט
    border: '1px solid #DDD',
    borderRadius: '5px',
    width: '100%', // מאפשר לתיבת הטקסט למלא את הרוחב הזמין
  };

  const buttonStyle = {
    marginTop: '20px', // מרווח מוגדל מהתיבה
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#007BFF',
    color: 'white',
    fontSize: '16px',
  };

  return (
    <div style={containerStyle}>
      <h2>הכנס קוד מסמך קורות חיים</h2>
      <input
        type="text"
        value={localId}
        onChange={handleChange}
        maxLength="12"
        placeholder="הכנס קוד..."
        style={inputStyle}
      />
      <button onClick={handleSubmit} style={buttonStyle}>הצג</button>
    </div>
  );
}

export default ResumeInput;

// import React, { useState } from 'react';

// function ResumeInput() {
//   const [localId, setLocalId] = useState('');

//   const handleChange = (event) => {
//     setLocalId(event.target.value);
//   };

//   const handleSubmit = () => {
//     // ניתוב לכתובת עם ה-localId
//     window.location.href = `/resume/displayer/${localId}`;
//   };

//   return (
//     <div>
//       <h2>הכנס קוד מסמך קורות חיים</h2>
//       <input
//         type="text"
//         value={localId}
//         onChange={handleChange}
//         maxLength="12"
//         placeholder="הכנס קוד..."
//       />
//       <button onClick={handleSubmit}>לצג</button>
//     </div>
//   );
// }

// export default ResumeInput;
