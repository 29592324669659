import React, { useEffect } from 'react';
import './AdvertisingPrices.css';

const AdvertisingPrices = () => {
  const pricingData = [
    {
      packageType: 'תוכן',
      options: [
        { views: '300 הופעות', price: '150 ש"ח' },
        { views: '1000 הופעות', price: '400 ש"ח' }
      ]
    },
    {
      packageType: 'רספונסיבי',
      options: [
        { views: '300 הופעות', price: '225 ש"ח' },
        { views: '1000 הופעות', price: '600 ש"ח' }
      ]
    },
    {
      packageType: 'PPR',
      options: [
        { views: '100 תגובות', price: '750 ש"ח' },
        { views: '300 תגובות', price: '1500 ש"ח' }
      ]
    }
  ];

  useEffect(() => {
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);


  return (
    <div className="container">
      <div className="header-title">חבילות פרסום למערכת הטלפונית של עזרן</div>
      <div className="pricing-table">
        <table>
          <thead>
            <tr>
              <th>סוג חבילה</th>
              <th>הופעות</th>
              <th>מחיר</th>
            </tr>
          </thead>
          <tbody>
            {pricingData.map((pkg) =>
              pkg.options.map((option, index) => (
                <tr key={index}>
                  <td>{pkg.packageType}</td>
                  <td>{option.views}</td>
                  <td>{option.price}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div>
        <a href='https://meshulam.co.il/quick_payment?b=bf80e6aacbeb68b4fc73a6be337cec83'>לתשלום ישיר לחץ כאן!</a>
      </div>
      <div className="footer-note">
        לחבילות מותאמות אישית, צור קשר בשלוחה 1 &gt; 7 (אחרי רישום)
      </div>
    </div>
  );
};

export default AdvertisingPrices;
