import React, { useState } from 'react';

const CourseSyllabus = () => {
  const syllabus = [
    {
      week: "שבוע 1",
      title: "מבוא להייטק והיכרות עם מערכת hazran",
      topics: [
        "היכרות עם תחום הבינה המלאכותית והשפעתה בעולם ההייטק",
        "היכרות עם מערכת hazran: מענה טלפוני מבוסס בינה מלאכותית",
        "סקירת מרכיבי המערכת: צד לקוח, צד שרת ומנוע ה-AI",
        "התקנה והגדרת סביבת העבודה עם hazran",
        "יסודות התכנות בשפת פיתון, פתיחה"
      ],
    },
    {
      week: "שבוע 2",
      title: "יסודות תכנות בשפת פייתון",
      topics: [
        "מבנה ופונקציות בסיסיות בשפת פייתון",
        "עבודה עם משתנים, תנאים ולולאות",
        "כתיבת פונקציות ואינטגרציה עם מערכות חיצוניות",
        "מבוא למודולים בסיסיים רלוונטיים לניהול מערכות טלפוניות",
      ],
    },
    {
      week: "שבוע 3",
      title: "בניית צד לקוח ב-hazran",
      topics: [
        "הבנת זרימת השיחה והעברת מידע לצד הלקוח",
        "פיתוח ממשקי משתמש בסיסיים לתקשורת עם מערכת hazran",
        "שילוב ממשקי קול וטקסט ליצירת חוויית משתמש אינטראקטיבית",
        "יצירת מערכות לניהול שיחות וניתוב מידע מבוסס AI",
      ],
    },
    {
      week: "שבוע 4",
      title: "צד שרת ב-hazran והפעלת שירותים מבוססי פייתון",
      topics: [
        "פיתוח צד שרת עם פייתון ו-API לניהול שירותי hazran",
        "הגדרת נקודות קצה (endpoints) לתקשורת עם הלקוח",
        "שמירת נתונים ומעקב אחר אינטראקציות עם המשתמש",
        "אבטחת המידע ומדיניות הרשאות לניהול שירותים רגישים",
      ],
    },
    {
      week: "שבוע 5",
      title: "הגדרת שירותים מותאמים אישית ב-hazran",
      topics: [
        "יצירת תסריטי שיחה מותאמים אישית במערכת hazran",
        "אינטגרציה של ניתוח שפה טבעית למענה על שאלות ופניות",
        "עיצוב תהליכי עבודה מותאמים לפי צרכים עסקיים",
        "ניהול והגדרת תהליכי אוטומציה",
        "לקראת פרוייקט סיום, גיבוש הרעיון"
      ],
    },
    {
      week: "שבוע 6-7",
      title: "פרויקט סיום - פיתוח מערכת מענה טלפוני אינטגרטיבית",
      topics: [
        "תכנון מערכת מענה טלפוני חכמה המשתמשת ב-hazran",
        "שילוב של צד שרת ולקוח עם פונקציות AI לשיפור השירות",
        "פרזנטציה וקבלת משוב על הפרויקטים",
      ],
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      <h1>סילבוס קורס מבוא להייטק בשפת python ומערכת hazran.online</h1>
      {syllabus.map((week, index) => (
        <div key={index}>
          <button onClick={() => toggleAccordion(index)} style={{ width: '100%', textAlign: 'right' }}>
            {week.week} - {week.title}
          </button>
          {/* {activeIndex === index && ( */}
            <ul>
              {week.topics.map((topic, i) => (
                <li key={i}>{topic}</li>
              ))}
            </ul>
          {/* )} */}
          <br />
        </div>
      ))}
      <center>
      עזרן - מענה בינה מלאכותית טלפוני 033765432
      </center>
    </div>
  );
};

export default CourseSyllabus;
