import React from 'react';

const ServiceExplanation = () => {
  return (
    <div>
      <h2>⭐ הזירה החופשית - תיאור כללי של המערכת</h2>
      <p>
        המערכת היא פלטפורמה מתקדמת המאפשרת למפתחים ליצור שירותים טלפוניים אינטראקטיביים, מבלי להתמודד עם האתגרים הכרוכים בניהול שיחות וניתוח מלל. 
        הפלטפורמה מנהלת את השיח עם משתמשי הקצה, מנתחת את הנתונים שמתקבלים מהם, ומעבירה את המידע בצורה מובנית לשרת השירות שלכם. כך, אתם יכולים להתמקד בפיתוח הלוגיקה העסקית של השירות, בעוד שהמערכת מטפלת באינטראקציה עם המשתמשים.
      </p>

      <h3>⭐ אופן השימוש במערכת</h3>

      <h4>⭐ חיבור ראשוני ורישום השירות:</h4>
      <ul>
        <li><strong>הגדרת URL ייעודי:</strong> כדי להתחיל להשתמש במערכת, עליכם לספק URL ייעודי שאליו תישלח בקשת POST לצורך רישום השירות שלכם.</li>
        <li><strong>תהליך הרישום:</strong> המערכת תבצע קריאת POST ל-URL זה עם הנתונים הבאים: <code>{"{"}"target": "registration"{"}"}</code>. נתיב זה ישמש גם לתקשורת עם השרת שלכם במהלך ביצוע השירות.</li>
        <li><strong>המידע שיש להחזיר לרישום:</strong> עליכם להחזיר את המידע הבא:
          <ul>
            <li><strong>manager_phone:</strong> מספר טלפון של מנהל השירות.</li>
            <li><strong>email:</strong> כתובת דוא"ל של המנהל.</li>
            <li><strong>connection_url:</strong> הנתיב (URL) שאליו יישלחו הנתונים שנאספו מהמשתמשים.</li>
            <li><strong>service_name:</strong> שם השירות שאתם מספקים.</li>
            <li><strong>brief_description:</strong> תיאור קצר של השירות.</li>
            <li><strong>audio_url:</strong> קובץ שמע להודעת פתיחה שתושמע למשתמש בתחילת השיחה.</li>
            <li><strong>message:</strong> טקסט להודעת פתיחה שתוקרא למשתמש בתחילת השיחה (מומלץ לנקד את הטקסט).</li>
            <li><strong>long_explanation:</strong> הסבר מפורט יותר על השירות שיושמע לפי הצורך.</li>
            <li><strong>required_data_schema:</strong> מבנה הנתונים הנדרש מהמשתמש (מילון פייתון, למבנה המילון - ראה שאלות ותשובות).</li>
            <li><strong>number_of_digits:</strong> מספר הספרות הנדרשות בתשובות מסוימות (למשל, עבור מספרי טלפון).</li>
            <li><strong>phone_number_required:</strong> האם נדרש מספר טלפון מהמשתמש (True/False).</li>
            <li><strong>email_required:</strong> האם נדרש דוא"ל מהמשתמש (True/False).</li>
            <li><strong>credit_card_required:</strong> האם נדרשים פרטי כרטיס אשראי (True/False).</li>
            <li><strong>system_payments:</strong> האם השירות תומך בתשלומים דרך המערכת שלכם (True/False).</li>
            <li><strong>external_payments:</strong> האם השירות תומך בתשלומים חיצוניים (True/False).</li>
            <li><strong>entry_amount_to_be_paid:</strong> סכום כניסה שיש לשלם במידת הצורך.</li>
            <li><strong>referral_phone:</strong> מספר טלפון להפניה ישירה לשירות.</li>
            <li><strong>analysis_delay:</strong> האם להמתין לניתוח תגובת המשתמש, ברירת המחדל היא  False, המתנה מקסימלית עד 6 שניות, יתר מכן בכל מקרה יוחזר רק הקישור לניתוח הנתונים לכשיגיעו</li>
            <li><strong>tracking_fields:</strong> שדות למעקב לממשק הניהול</li>
            <li><strong>returned_data:</strong> ערכים שאתם מעוניינים שיחזרו לקריאה הבאה</li>
            </ul>
        </li>
      </ul>
      <p><strong>הערה:</strong> גם ערכים שאינם רלוונטיים יש להחזיר עם ערך אתחול מתאים (מחרוזת ריקה, 0, או False).</p>

      <h4>⭐ ניהול השיחה:</h4>
      <p>
        לאחר שהשירות נרשם במערכת, היא תתחיל לנהל שיחה עם משתמשי הקצה על פי ההנחיות שהגדרתם. המערכת תאסוף את המידע מהמשתמשים ותעביר אותו לשרת שלכם להמשך עיבוד.
      </p>

      <h4>⭐ תקשורת לולאתית עם השרת:</h4>
      <p>
        לאחר כל שליחת מידע לשרת שלכם, תוכלו להחזיר הנחיות נוספות להמשך השיחה. המערכת תבצע את השיחה בהתאם להנחיות אלו, וההליך יחזור על עצמו בצורה לולאתית עד להשלמת כל הנתונים הנדרשים מהמשתמש.
      </p>

      <h3>⭐ דרישות מערכת</h3>
      <ul>
        <li><strong>שרת זמין:</strong> ה-URL הייעודי שאליו יישלחו הנתונים שנאספו הוא אותו נתיב של הרישום.</li>
        <li><strong>מבנה נתונים ברור:</strong> עליכם להגדיר מבנה נתונים ברור ומדויק לכל סוג מידע שהמערכת צריכה לאסוף מהמשתמשים.</li>
        <li><strong>ניהול התהליך:</strong> עליכם לנהל את תהליך השירות ולוודא שהשירות מספק את כל הנתונים הדרושים לפלטפורמה, כולל כל הדרישות המיוחדות לתהליך השיחה.</li>
      </ul>

      <h3>⭐ תשובת המערכת לשרת</h3>
      <p>
        כאשר המערכת שולחת מידע לשרת שלכם, היא תשלח את הפרמטרים הבאים: (כולם או חלקם בהתאם להגדרות של התקשורת בין השירות למשתמש)
      </p>
      <ul>
        <li><strong>route_id:</strong> מזהה של המסלול שבו נמצא המשתמש במערכת.</li>
        <li><strong>target:</strong> ערך זה מוגדר תמיד כ-"service_processing", ומציין שהמידע שנשלח מיועד לעיבוד על ידי השירות.</li>
        <li><strong>last_local_id:</strong> מזהה מקומי של הפעולה האחרונה שבוצעה על ידי המשתמש.</li>
        <li><strong>local_id:</strong> מזהה מקומי חדש שנוצר עבור הפעולה הנוכחית.</li>
        <li><strong>call_id:</strong> מזהה ייחודי של השיחה, המקושר למידע שנאסף במהלך השיחה.</li>
        <li><strong>phone_number:</strong> (מותנה) מספר הטלפון של המשתמש אם נדרש.</li>
        <li><strong>email:</strong> (מותנה) כתובת הדוא"ל של המשתמש אם נדרש.</li>
        <li><strong>digits:</strong> (מותנה) הספרות שהמשתמש הקיש במהלך השיחה.</li>
        <li><strong>Recording:</strong> (מותנה) תמלול הקלטת השיחה.</li>
        <li><strong>file_url:</strong> כתובת URL לקובץ הקול שנשמר.</li>
        <li><strong>user_content:</strong> טקסט המתקבל מעיבוד הקול.</li>
        <li><strong>required_data:</strong> אובייקט המכיל את הנתונים שהמשתמש נדרש לספק בהתאם לסכמה שהוגדרה.</li>
        <li><strong>analysis_response_url:</strong> נתב לקבלת תוצאות ניתוח תשובת המשתמש.</li>
        <li><strong>analysis_status:</strong> סטאטוס ניתוח הנתונים, האם כבר סויים הניתוח בזמן ההחזר.</li>
        <li><strong>returned_data:</strong> אותם ערכים ששלחתם.</li>
            </ul>

      <h3>⭐ נתונים שהשרת שלכם יכול להחזיר</h3>
      <ul>
        <li><strong>message:</strong> (אופציונלי) הודעה טקסטואלית להקראה (מומלץ לנקד את הטקסט).</li>
        <li><strong>audio_url:</strong> (אופציונלי) כתובת URL של קובץ שמע לניגון.</li>
        <li><strong>number_of_digits:</strong> (אופציונלי) מספר הספרות שהמשתמש צריך להקיש.</li>
        <li><strong>required_data_schema:</strong> (אופציונלי) סכמה של נתונים שהמשתמש נדרש לספק. (למבנה המילון - ראה שאלות ותשובות) </li>
        <li><strong>amount_to_be_paid:</strong> (אופציונלי) סכום כסף שהמשתמש צריך לשלם.</li>
        <li><strong>returned_data:</strong> ערכים שאתם מעוניינים שיחזרו לקריאה הבאה.</li>
        <li><strong>email_me_on_service_end_data:</strong> נתונים לאימייל לדיווח בסיום שיחה.</li>
        </ul>
שים לב: בכל פניה של המערכת לשרת השירות , השרת חייב להחזיר תגובת json עם סטאטוס 200, אי החזרת תגובת json או תגובה ללא סטאטוס 200 תגרום להשהית השירות עקב בעיות תקשורת, במידה והשרות הסתיים, יש להחזיר תגובת json  ריקה עם סטאטוס 200
      <h3>⭐ משמעות הנתונים וסדר עדיפויות</h3>
      <ul>
        <li><strong>audio_url:</strong> אם קיים, ינוגן במקום הודעה טקסטואלית.</li>
        <li><strong>message:</strong> ינוגן רק אם אין קובץ שמע.</li>
        <li><strong>number_of_digits:</strong> יחליף את זיהוי המלל אם מוגדר.</li>
        <li><strong>amount_to_be_paid:</strong> תוצג רק אם אין הודעה או קובץ שמע.</li>
        <li><strong>email_me_on_service_end_data:</strong> האימייל ישלח רק אם לא ניתן אף אחד מהפרמטרים הנ"ל.</li>
        </ul>

        <h3>⭐ דוגמאות קוד</h3>
      <ul>
        <li> <a href={`${window.location.origin}/FreeArena/content/lessonExample`} >
        <strong>השמעת שיעורים:   </strong> 
          {`${window.location.origin}/FreeArena/content/lessonExample`}
        </a></li>
        <li><a href={`${window.location.origin}/FreeArena/content/productsExample`}>
        <strong>הזמנת מוצרים:   </strong> 
          {`${window.location.origin}/FreeArena/content/productsExample`}
        </a>
      </li>
      <li><a href={`${window.location.origin}/FreeArena/content/havrutaExample`}>
        <strong>זירת החברותות:  </strong> 
          {`${window.location.origin}/FreeArena/content/havrutaExample`}
        </a>
      </li>
      
        </ul>

      <h2>⭐ שאלות ותשובות</h2>

      <ul>
        <li>
          <strong>שאלה:</strong> אם השרת שלי משתמש באותו URL לרישום ותקשורת, איך אני יודע לאיזו פעולה השרת שלכם פנה אלי?<br />
          <strong>תשובה:</strong> יש פרמטר בשם <strong>target</strong> שנשלח עם הבקשה. פרמטר זה יכול להיות אחד משני ערכים:
          <ul>
            <li><code>{"{"}"target":"registration"{"}"}</code> לצורך רישום או עדכון.</li>
            <li><code>{"{"}"target":"service_processing"{"}"}</code> לצורך עיבוד שירות.</li>
          </ul>
        </li>
        <br />
        <li>
          <strong>שאלה:</strong> איך אני מקשר בין קריאות שונות כדי לוודא שמדובר באותו משתמש?<br />
          <strong>תשובה:</strong> ניתן להשתמש בפרמטר <strong>call_id</strong>. פרמטר זה הוא מזהה ייחודי שמאפשר לקשר בין כל הקריאות של אותו המשתמש במהלך השיחה.
        </li>
        <br />
        <li>
          <strong>שאלה:</strong> איך אני יודע אם זו תחילת בקשה לשירות או קריאה נוספת במהלך השירות?<br />
          <strong>תשובה:</strong> פרמטר <strong>last_local_id</strong> עוזר לזהות את מצב הבקשה. אם הערך של <strong>last_local_id</strong> הוא 0, מדובר בקריאה הראשונה. בנוסף, ניתן לבדוק את היסטוריית הקריאות על ידי שימוש בפרמטר <strong>call_id</strong>, דרך נוספת לזהות תחילת שיחה או למקם שלב במהלך השיחה היא על ידי הפרמטר <strong>returned_data</strong> אותם ניתן להגדיר כבר בשלב הרישום ולשנות אותם מקריאה לקריאה.
        </li>
        <br />
        <li>
          <strong>שאלה:</strong> האם אני יכול לגבות תשלום עבור השירות שלי?<br />
          <strong>תשובה:</strong> כן, באפשרותך לגבות תשלום עבור השירות. אם המשתמש מאשר את התשלום, יש להחזיר את הסכום בפרמטר <strong>amount_to_be_paid</strong> ולהשאיר את כל יתר הפרמטרים ריקים. ניתן גם להגדיר תשלום מראש כתנאי לכניסה לשירות.
        </li>
        <br />
        <li>
          <strong>שאלה:</strong> איך אני מעדכן את פרטי הרישום?<br />
          <strong>תשובה:</strong> דרך הממשק הטלפוני יש אפשרות לעדכן את פרטי הרישום. במקרה כזה, תישלח אליך שוב פניה עם <code>{"{"}"target":"registration"{"}"}</code>.
        </li>
        <br />
        <li>
          <strong>שאלה:</strong> איך אני מוחק או משהה את השירות שלי?<br />
          <strong>תשובה:</strong> דרך הממשק הטלפוני ניתן למחוק או להשעות את השירות.
        </li>
        <br />
        <li>
          <strong>שאלה:</strong> איזה פרמטרים עלי לשלוח כדי שהמערכת תקלוט מלל מהמשתמש , תנתח אותו ותחזיר את הערכים הנדרשים?<br />
          <strong>תשובה:</strong> המערכת תקלוט מהמשתמש מלל קולי ותנתח אותו , בשלושה תנאים<br />
           האחד הוא שיש ערכים לפחות באחד מהפרמטרים audio_url או message <br />
           השני , שהערך של number_of_digits הוא 0, מכיוון שאם יש ערך ב number_of_digits המערכת תעדיף לקלוט את התווים על מלל קולי  <br />
           והשלישי והמרכזי הוא שיש ערכים בrequired_data_schema, ערכים אלו הם המפתחות של התוכן אותו תחזיר המערכת מיתוך ניתוח מלל המשתמש
        </li>
        <br />
        <li>
          <strong>שאלה:</strong> מה המבנה של required_data_schema אותו יש לשלוח?<br />
          <strong>תשובה:</strong> required_data_schema הוא סכמה של הפרמטרים שאותם השרות מבקש לקבל מיתוך הקלט הקולי של המשתמש.<br />
          required_data_schema צריך להיות בנוי שדיקשינרי כאשר המפתחות הם שמות הערכים הנדרשים והערך שלהם הוא הסוג של המשתנה המתבקש , כמחרוזת ולא כ type (למשל 'int' ולא int)<br />
          סוגי המשתנים האפשריים הם : 'str', 'int', 'float', 'bool', 'dict', 'list' <br />
          המפתחות , כאמור , הם שמות הערכים אותם השרות מבקש לקבל, אין מקום להסבר נוסף על הדרישות לערכים ולכן המשמעות והתוכן של הפרמטרים האלו צריך להיות ברור מתוך השם שלהם<br />
        </li>
      </ul>
    </div>
  );
};

export default ServiceExplanation;
