import React, { useState, useEffect } from 'react';
import './JobEntry.css'; 

function JobEntry() {

  const [formState, setFormState] = useState({
    jobDescription: '',
    requirements: '',
    education: '',
    location: '',
    salary: '',
    phone: '',
    email: '',
    publisherContact: new URLSearchParams(window.location.search).get('publisherContact'), // ניתן להשתמש בשדה זה גם עבור טלפון וגם עבור אימייל
    password: '',
  });


  useEffect(() => {
            // הגדרת רקע לכל העמוד
            document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);



  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // אימות של השדות החובה
    // if (!formState.jobDescription || !formState.publisherContact || !formState.password) {
      if (!formState.jobDescription) {
      // alert('חסרים שדות חובה!');
      alert('חסר תאור למשרה');
      return;
    }
    if (!formState.email && !formState.phone) {
      alert('נא למלא לפחות אימייל או טלפון!');
      return;
    }

 
    let jobPost="תאור המשרה: "+formState.jobDescription
    formState.requirements&&(jobPost=jobPost+".  דרישות: "+formState.requirements)
    formState.education&&(jobPost=jobPost+".  דרישות השכלה: "+formState.education)
    formState.location&&(jobPost=jobPost+".  מיקום: "+formState.location)
    formState.salary&&(jobPost=jobPost+".  תנאי שכר: "+formState.salary)
    formState.phone&&(jobPost=jobPost+".  טלפון: "+formState.phone)
    formState.email&&(jobPost=jobPost+".  אימייל: "+formState.email)
    const postData = {
      job_post: jobPost,
      password: formState.password,
    };
  try {
          const response = await fetch('https://server.hazran.online/job_search/crude_job_entry', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            // body: JSON.stringify(formState),
            body: JSON.stringify(postData),

          });
          
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          // const data = await response.json();
          const data = response.text;
          console.log(data);
    
          // setJobPost(''); // איתחול תיבת הקלט לטקסט
          setFormState({jobDescription: '',
          requirements: '',
          education: '',
          location: '',
          salary: '',
          phone: '',
          email: '',
          publisherContact: new URLSearchParams(window.location.search).get('publisherContact'), // ניתן להשתמש בשדה זה גם עבור טלפון וגם עבור אימייל
          password: '',})
          alert('נתונים נשלחו בהצלחה');
        } catch (error) {
          console.error('שגיאה בשליחת הנתונים:', error);
        }
      };

      return (
        <form onSubmit={handleSubmit} className="jobForm" style={{ direction: 'rtl' }}>
           <h2 style={{ textAlign: 'center' }}>פרסום משרה חדשה</h2>
          <div className="formGroup">
            <label>תיאור המשרה<span style={{ color: 'red' }}>*</span>:</label>
            <textarea name="jobDescription" required value={formState.jobDescription} onChange={handleChange} rows="5" style={{ width: '100%' }} />
          </div>
          <div className="formGroup">
            <label>דרישות:</label>
            <input name="requirements" value={formState.requirements} onChange={handleChange} />
          </div>
          <div className="formGroup">
            <label>השכלה נדרשת:</label>
            <input name="education" value={formState.education} onChange={handleChange} />
          </div>
          <div className="formGroup">
            <label>מיקום:</label>
            <input name="location" value={formState.location} onChange={handleChange} />
          </div>
          <div className="formGroup">
            <label>תנאי שכר:</label>
            <input name="salary" value={formState.salary} onChange={handleChange} />
          </div>
          <div className="formGroup">
            <label>טלפון:</label>
            <input name="phone" value={formState.phone} onChange={handleChange} />
          </div>
          <div className="formGroup">
            <label>אימייל:</label>
            <input name="email" type="email" value={formState.email} onChange={handleChange} />
          </div>
          {/* <div className="formGroup">
            <label>טלפון או אימייל של מפרסם המשרה<span style={{ color: 'red' }}>*</span>:</label>
            <input name="publisherContact" required value={formState.publisherContact} onChange={handleChange} />
          </div>
          <div className="formGroup">
            <label>סיסמה<span style={{ color: 'red' }}>*</span>:</label>
            <input name="password" type="password" required value={formState.password} onChange={handleChange} />
          </div> */}
          <div className="buttonContainer">
            <button type="submit">פרסם</button>
          </div>

        </form>
      );
      }  
export default JobEntry;
