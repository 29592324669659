import React from 'react';
import ServiceDescription from './instructionsContent'; // הנתיב אל ParentComponent
import ServiceTerms from './termsContent'; // הנתיב אל ParentComponent
import ContentContainer from '../contentContainer'
import ProductsExample from './productsExampleContent'; // הנתיב אל ParentComponent
import LessonExample from './lessonExampleContent'; // הנתיב אל ParentComponent
import HavrutaExample from './havrutaExampleContent'; // הנתיב אל ParentComponent
import CourseSyllabus from './CourseSyllabusContent'; // הנתיב אל ParentComponent
import { useParams } from 'react-router-dom';



const FreeArenaContents = () => {
  const { content } = useParams();
  let contentComponent
  if (content.toLowerCase() == "instructions"){
    contentComponent=<ServiceDescription />
  }
  if (content.toLowerCase() == "terms"){
    contentComponent=<ServiceTerms />
  }
  if (content.toLowerCase() == "productsexample"){
    contentComponent=<ProductsExample />
  }
  if (content.toLowerCase() == "lessonexample"){
    contentComponent=<LessonExample />
  }
  if (content.toLowerCase() == "havrutaexample"){
    contentComponent=<HavrutaExample />
  }
  if (content.toLowerCase() == "coursesyllabus"){
    contentComponent=<CourseSyllabus />
  }
  return (
    <ContentContainer>
      {contentComponent}
    </ContentContainer>
  );
}

export default FreeArenaContents;
