import React, { useState, useEffect } from 'react';
import './getCredit.css';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import Cookies from 'js-cookie';

function GetCredit() {
  const domain = 'https://server.hazran.online';

  const service = Cookies.get('service');
  const amount = Cookies.get('amount');
  const type = Cookies.get('type');
  const remarks = Cookies.get('remarks');
  const salesPhoneNumber = Cookies.get('salesPhoneNumber');

  useEffect(() => {
    document.body.classList.add('get-credit-body');
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    return () => {
      document.body.classList.remove('get-credit-body');
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);

  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [email, setEmail] = useState(Cookies.get('managerEmail'));
  const [phone, setPhone] = useState((salesPhoneNumber ? Cookies.get('managerPhoneNumber') : Cookies.get('phoneNumber'))|| '');
  const [userId, setUserId] = useState('');
  const [valid, setValid] = useState(true);
  const [showServiceDetails, setShowServiceDetails] = useState(true);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [focus, setFocus] = useState('');

  const checkCreditCardValidity = (cardNumber) => {
    cardNumber = cardNumber.replace(/\s+/g, '');
    if (!/^\d+$/.test(cardNumber)) {
      return false;
    }

    let total = 0;
    let reverseDigits = cardNumber.split('').reverse().map(d => parseInt(d));

    for (let i = 0; i < reverseDigits.length; i++) {
      let n = reverseDigits[i];
      if (i % 2 === 1) {
        n *= 2;
        if (n > 9) {
          n -= 9;
        }
      }
      total += n;
    }

    return total % 10 === 0;
  };

  const checkExpirationDateValidity = (date) => {
    const [month, year] = date.split('/').map(Number);
    if (!month || !year || month < 1 || month > 12) return false;

    const now = new Date();
    const expiration = new Date(`20${year}`, month - 1);
    return expiration >= now;
  };

  const checkUserIdValidity = (id) => {
    if (id.length !== 9 || !/^\d+$/.test(id)) return false;
    
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      let num = parseInt(id[i]) * ((i % 2) + 1);
      if (num > 9) num -= 9;
      sum += num;
    }

    return sum % 10 === 0;
  };

  const formatCardNumber = (number) => {
    return number.replace(/\s+/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (showServiceDetails) {
      setShowServiceDetails(false);
    } else {
      if (!showUserDetails) {
        const isCardNumberValid = checkCreditCardValidity(cardNumber);
        const isExpirationDateValid = checkExpirationDateValidity(expirationDate);
        const isUserIdValid = checkUserIdValidity(userId);
        setValid(isCardNumberValid && isExpirationDateValid && isUserIdValid);
        if (isCardNumberValid && isExpirationDateValid && isUserIdValid) {
          setShowUserDetails(true);
        } else {
          alert('מספר הכרטיס, תוקף הכרטיס או תעודת הזהות אינם תקינים.');
        }
      } else {
        const paymentData = {
          token: Cookies.get('verificationToken'),
          phone: phone,
          credit_number: cardNumber.replace(/\s+/g, ''),
          expiration_date: expirationDate,
          cvv: cvv,
          card_holder_name: cardholderName,
          email: email,
          address: '',
          amount: amount,
          service: service,
          type: type,
          remarks: remarks,
          representativ_phone: salesPhoneNumber,
          user_id: userId,
        };

        try {
          let path;
          if (!salesPhoneNumber) {
            path = `${domain}/site_api/payments/insert_payment_data_for_new_credit_card`;
          } else {
            path = `${domain}/site_api/payments/insert_payment_data_for_new_credit_card_by_sales_representativ`;
          }
          const response = await fetch(path, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData),
          });

          const result = await response.json();

          if (result.success) {
            alert('הפרטים נשמרו בהצלחה!');
            window.location.href = salesPhoneNumber ? `/PaymentManagement` : `/ThankYouPage`;
          } else {
            alert(`שגיאה: ${result.message}`);
          }
        } catch (error) {
          console.error('Error:', error);
          alert('שגיאה בעת שליחת הנתונים לשרת.');
        }
      }
    }
  };

  const formatExpirationDate = (value) => {
    const cleaned = value.replace(/\D+/g, '');
    const match = cleaned.match(/^(\d{0,2})(\d{0,2})$/);

    if (match) {
      const part1 = match[1];
      const part2 = match[2];

      if (part2) {
        return `${part1}/${part2}`;
      } else {
        return part1;
      }
    }
    return value;
  };

  return (
    <div className="get-credit-container">
      <form className="get-credit-form" onSubmit={handleSubmit}>
        <h1 className="get-credit-title">דף תשלום</h1>
        {showServiceDetails ? (
          <div className="get-credit-service-label">
            <div>השרות: {service}</div><br />
            <div>סכום לתשלום: {amount}</div><br />
            <div>אופן גביה: {type}</div><br />
            <div>הערות : {remarks}</div><br />
            <button className="get-credit-button" type="submit">אישור</button>
          </div>
        ) : !showUserDetails ? (
          <>
            <div className="custom-card">
              <Cards
                number={cardNumber}
                name={cardholderName}
                expiry={expirationDate}
                cvc={cvv}
                focused={focus}
              />
              <label className="get-credit-label">מספר כרטיס אשראי:</label>
              <input
                dir='ltr'
                className="get-credit-input short-input1"
                type="text"
                value={formatCardNumber(cardNumber)}
                onChange={(e) => setCardNumber(e.target.value)}
                onFocus={() => setFocus('number')}
                required
                inputMode="numeric"
                autoComplete="cc-number"
                name="cc-number"
              />
            </div>
            <div className="card-details-row">
              <div>
                <label className="get-credit-label">תוקף כרטיס (MM/YY):</label>
                <input
                  className="get-credit-input short-input2"
                  type="text"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(formatExpirationDate(e.target.value))}
                  onFocus={() => setFocus('expiry')}
                  required
                  inputMode="numeric"
                  pattern="\d{2}/\d{2}"
                  autoComplete="cc-exp"
                  name="cc-exp"
                />
              </div>
              <div>
                <label className="get-credit-label">קוד CVV:</label>
                <input
                  className="get-credit-input short-input2"
                  type="text"
                  value={cvv}
                  onChange={(e) => setCvv(e.target.value)}
                  onFocus={() => setFocus('cvc')}
                  required
                  inputMode="numeric"
                  pattern="\d{3,4}"
                  autoComplete="cc-csc"
                  name="cc-csc"
                />
              </div>
            </div>
            <div>
              <label className="get-credit-label">תעודת זהות:</label>
              <input
                className="get-credit-input short-input1"
                type="text"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                required
                inputMode="numeric"
                name="user-id"
              />
            </div>
            <button className="get-credit-button" type="submit">המשך</button>
          </>
        ) : (
          <>
            <div className="custom-card">
              <Cards
                number={cardNumber}
                name={cardholderName}
                expiry={expirationDate}
                cvc={cvv}
                focused={'name'}
              />
              <label className="get-credit-label">שם בעל הכרטיס:</label>
              <input
                className="get-credit-input short-input1"
                type="text"
                value={cardholderName}
                onChange={(e) => setCardholderName(e.target.value)}
                required
                autoComplete="cc-name"
                name="cc-name"
              />
            </div>
            <div>
              <label className="get-credit-label">אימייל:</label>
              <input
                className="get-credit-input short-input1"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                autoComplete="email"
              />
            </div>
            <div>
              <label className="get-credit-label">מספר טלפון בעל הכרטיס:</label>
              <input
                className="get-credit-input short-input1"
                type="tel"
                value={phone}
                required
                autoComplete="tel"
              />
            </div>
            <button className="get-credit-button" type="submit">שלח</button>
          </>
        )}
        {!valid && <p className="get-credit-error">מספר כרטיס האשראי, תוקף הכרטיס או תעודת הזהות אינם תקינים.</p>}
      </form>
    </div>
  );
}

export default GetCredit;
