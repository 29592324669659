import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './ResumePage.css'; // ייבוא קובץ ה-CSS
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ShareComponent from '../../components/ShareComponent';
import ClipLoader from "react-spinners/ClipLoader"

function ResumeDisplayer() {
  const { localId } = useParams();

  const [resumeData, setResumeData] = useState([])
  const [spinner, setSpinner] = useState(true)
  const path = "https://server.hazran.online/site_api/resume/get_short_resume/"+localId; // החלף עם הנתיב האמיתי ל-API שלך
  // const path = "https://51.17.149.153/site_api/resume/get_short_resume/"+localId; // החלף עם הנתיב האמיתי ל-API שלך
  // const path = "http://localhost:5000/site_api/resume/get_short_resume/"+localId; // החלף עם הנתיב האמיתי ל-API שלך
  // const path = "https://51.16.27.181/site_api/resume/get_short_resume/"+localId; // החלף עם הנתיב האמיתי ל-API שלך

  useEffect(() => {
    fetch(path)
      .then(response => response.json())
      .then(data => {setResumeData(data)
      setSpinner(false)})
      .catch(error => {console.error("Error fetching data:", error)
      setSpinner(false)});
  }, [path]);


  useEffect(() => {
    // הגדרת רקע לכל העמוד
    document.body.style.backgroundImage = 'url("/background.jpg")';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';

    // יצירת הקישור דינמית
    const link = document.createElement('a');
    link.href = 'https://hazran.online';
    link.textContent = ' עזרן - נתב בינה מלאכותית טלפוני hazran.online';
    link.style.display = 'block';
    link.style.textAlign = 'center';
    link.style.marginTop = '20px';
    link.style.color = '#ffffff';
    link.style.textDecoration = 'none';
    link.style.fontSize = '14px';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';

    // הוספת הקישור לגוף הדף
    document.body.appendChild(link);

    // פונקציה לניקוי - מחזירה את הרקע למצבו הרגיל ומסירה את הקישור בעת ניטרול הקומפוננטה
    return () => {
      document.body.style.backgroundImage = '';
      document.body.style.backgroundSize = '';
      document.body.style.backgroundPosition = '';
      document.body.removeChild(link);
    };
  }, []);
  // כותרות מוגדרות מראש בעברית
  const titles = {
    "personal_details": "פרטים אישיים",
    "professional_summary": "סיכום מקצועי",
    "education": "השכלה",
    "professional_experience": "ניסיון מקצועי",
    "professional_achievements": "הישגים מקצועיים",
    "skills": "כישורים",
    "languages": "שפות",
    "personal_statement": "הצהרה אישית",
    "optional": "נתונים נוספים"
  };

  // const printDocument = () => {
  //   const input = document.getElementById('resume-container');
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
      
  //     // יצירת אובייקט PDF עם גודל דף A4
  //     const pdf = new jsPDF('p', 'mm', 'a4');
      
  //     // מידות דף A4 במילימטרים
  //     const pageWidth = 210;
  //     const pageHeight = 297;
      
  //     // מחשב את היחס שבין רוחב התמונה לרוחב הדף
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = pageWidth / imgWidth;
  //     const scaledHeight = imgHeight * ratio;
  
  //     // אם התמונה גבוהה מדי לדף, ניתן לחתוך או לשנות את הקוד כאן
  //     let heightLeft = scaledHeight;
  //     let position = 0;
  
  //     while (heightLeft >= 0) {
  //       position = heightLeft - scaledHeight;
  //       pdf.addImage(imgData, 'PNG', 0, position, pageWidth, scaledHeight);
  //       heightLeft -= pageHeight;
  //       if (heightLeft > 0) {
  //         pdf.addPage();
  //       }
  //     }
  
  //     pdf.save("resume.pdf");
  //   });
  
  // }
  const printDocument = () => {
    const input = document.getElementById('resume-container');
    html2canvas(input, {scale: 2}).then((canvas) => { // ניתן לשחק עם הערך scale להתאמת האיכות
      const imgData = canvas.toDataURL('image/png');
      
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const widthRatio = pageWidth / imgWidth;
      const heightRatio = pageHeight / imgHeight;
      const ratio = Math.min(widthRatio, heightRatio); // בוחר ביחס הקטן ביותר להתאמת התמונה לעמוד
      const canvasScaledWidth = imgWidth * ratio;
      const canvasScaledHeight = imgHeight * ratio;
  
      // ממקם את התמונה במרכז העמוד
      const x = (pageWidth - canvasScaledWidth) / 2;
      const y = (pageHeight - canvasScaledHeight) / 2;
  
      pdf.addImage(imgData, 'PNG', x, y, canvasScaledWidth, canvasScaledHeight);
      pdf.save("resume.pdf");
    });
  }
  
  const sendEmailWithLink = () => {
    const subject = encodeURIComponent("קורות חיים - שמי");
    const currentPageUrl = window.location.href; // ה-URL של הדף הנוכחי
    const body = encodeURIComponent(`שלום,\n\nאני מעוניין להגיש את קורות חיים שלי לשקול.\n\nניתן לצפות בקורות החיים שלי בקישור הבא:\n\n ${currentPageUrl}\n\nתודה,`);
    
    // פתיחת לקוח האימייל המוגדר כברירת מחדל עם הפרטים הרלוונטיים
    window.open(`mailto:?subject=${subject}&body=${body}`);
  };
  
  return (
    <div>
    <div id="resume-container" className="resume-container rtl">
      {((resumeData.length === 0) && (!spinner))?<div style={{ textAlign: 'center' }}><h1>לא נמצאו קורות החיים הרלוונטיים, אנא בדוק את קוד המסמך</h1>
        <a href='/resume/insert_code'>לחץ לחזרה להזנת קוד</a>
      </div>:null}
      <div style={{ textAlign: 'center' }}>
        <ClipLoader
        loading={spinner}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      </div>
      {resumeData.map(([key, value]) => {
        if (!value) return null;

        return (
          <div key={key} className={`resume-section ${key.replace(/ /g, '-').toLowerCase()}`}>
            <h2>{titles[key]}</h2>
            {/* <p>{value}</p> */}
            <p>
      {value.split('***').map((part, index, array) => (
        <React.Fragment key={index}>
          {part.trim()}
          {index !== array.length - 1 && <br />}
        </React.Fragment>
      ))}
    </p>
          </div>
        );
      })}
      <div className="created-by">
      <a href="https://www.hazran.online/">
        נוצר על ידי עזרן - מענה בינה מלאכותית טלפוני 03-3765432
        </a>
      </div>
      </div>
      <div className="download-button-container">
      <button className="download-button" onClick={printDocument}>הורד כ PDF</button>
      <ShareComponent />
      <button onClick={sendEmailWithLink} className="email-send-button">שלח קורות חיים באימייל</button>
   
    </div>
    </div>
  );
};

export default ResumeDisplayer;
