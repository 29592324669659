import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const LessonExample = () => {
        const code = `
        from flask import Flask, jsonify, request
        import json

        app = Flask(__name__)


        manager_phone="....."  # הכנס את הטלפון שלך, טלפון  זה ישמש לניהול השירות
        email="....."  # הכנס את האימייל שלך
        base_url="....."

        @app.route('/', methods=['GET'])
        def welcome():
            return "ברוך הבא", 200


        @app.route('/lesson_service', methods=['POST'])
        def lesson_service():
            target = request.json.get('target')
            if target == 'registration':
                # חלק הרישום של השירות
                service_data = {
                    'manager_phone': manager_phone,  # מספר טלפון של מנהל השירות
                    'email': email,
                    'connection_url': base_url+"lesson_service",
                    'service_name': 'השמעת שיעורים',  
                    'brief_description': 'שירות להשמעת שיעורים בפרשות השבוע',  
                    'message': 'לשמיעת השעור על פרשת ניצבים לְחַץ 1, לשמיעת השעור על פרשת כי תבוא לְחַץ 2',
                    'audio_url':'',
                    'long_explanation': '',  # הסבר נוסף
                    'required_data_schema': {},  # אין צורך במבנה נתונים מורכב
                    'number_of_digits': 1,  # אנחנו מצפים ללחיצה אחת בלבד
                    'phone_number_required': False,  # אין צורך במספר טלפון מהמשתמש
                    'email_required': False,  # אין צורך בדוא"ל מהמשתמש
                    'credit_card_required': False,  # אין צורך בתשלום
                    'system_payments': False,  # השירות לא דורש תשלומים דרך המערכת
                    'external_payments': False,  # השירות לא דורש תשלומים חיצוניים
                    'entry_amount_to_be_paid': 0,  # ללא תשלום
                    'referral_phone': ''  # מספר טלפון להפניה ישירה לשרות
                }
                return jsonify(service_data), 200
            
            elif target == 'service_processing':
                # חלק ניהול השירות והטיפול בלחיצה של המשתמש
                digit=request.json.get('digits')
                aws_s3_url="https://.......s3.us-east-1.amazonaws.com/"
                if digit == '1':
                    audio_url = aws_s3_url+"nytzavim.mp3"
                    
                elif digit == '2':
                    audio_url = aws_s3_url+"kytabo.mp3"
                else:
                    return jsonify({
                        'message': 'הבחירה לא חוקית, נסה שוב.',
                        'number_of_digits':1
                    }), 200
                
                return jsonify({
                    'audio_url': audio_url
                }), 200
            return jsonify({'error': 'Invalid target'}), 200



        if __name__ == '__main__':
            app.run(host='0.0.0.0', port=5000)
  
        
        `;

    
const codeBlockStyle = {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    };
  return (
    <div dir="ltr" style={codeBlockStyle}>
  <SyntaxHighlighter language="javascript" style={dark} customStyle={{ backgroundColor: '#000', color: '#fff' }} >
  {code}
   </SyntaxHighlighter></div>
);
};

export default LessonExample;
