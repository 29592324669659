import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';


const HavrutaExample = () => {
        const code = `
from flask import Flask, jsonify, request
import json
import uuid
import threading
from database_functions import (
        set_data,
        set_data_by_id,
        get_data,
        get_data_by_id,
        get_data_list,
        set_call_data,
        set_call_data_by_id,
        get_call_data,
        get_call_data_by_id,
        get_call_data_list,
        delete_call_data,
        delete_data_by_key_and_id,
        delete_data_by_key,
        delete_data_by_id,
    )

from utils import (
    TemporaryRequest,
    get_analysis_response,
    wait_for_analysis_response,
    get_similarity_results,
    get_similarity_rate,
    get_next_item,
    normalize_phone_number
)

import get_data as gd
app = Flask(__name__)

manager_phone="....."  # הכנס את הטלפון שלך, טלפון  זה ישמש לניהול השירות
email="....."  # הכנס את האימייל שלך
base_url="....."

@app.route('/', methods=['GET'])
def welcome():
    return "ברוך הבא", 200


@app.route('/havruta_service', methods=['POST'])
def havruta_service():
    target = request.json.get('target')
    if target == 'registration':
        # חלק הרישום של השירות
        service_data = {
            'manager_phone': manager_phone,
            'email': email,
            'connection_url': base_url + "havruta_service",
            'service_name': 'זירת החברותות',
            'brief_description': 'שרות זה מאפשר למשתמשים להציע עצמם לחברותות ולמצוא שותפים ללמידה',
            'message': 'אֲנַחְנוּ שְׂמֵחִים לִרְאוֹת אוֹתְךָ בְּזִירַת הַחַבְרוּתוֹת, לְחִפּוּשׂ חַבְרוּתָא וְרִשּׁוּם  לַמַּאֲגָר לְחַץ 1, לִמְחִיקַת רִשּׁוּמִים קוֹדְמִים לְחַץ 2  ',
            'audio_url': '',
            'long_explanation': 'בזירת החברותות, משתמשים יכולים למצוא שותפים ללמידה על פי נושא ומיקום',
            'required_data_schema': {},
            'number_of_digits': 1,
            'phone_number_required': True,
            'email_required': False,
            'credit_card_required': False,
            'system_payments': False,
            'external_payments': False,
            'entry_amount_to_be_paid': 0,
            'referral_phone': '',
            'analysis_delay': 0
        }
        return jsonify(service_data), 200
    elif target == 'service_processing':
        try:
            stage=get_call_data('next_stage')
            digits=request.json.get('digits')
            if not stage: # this is a new call
                phone_number=request.json.get('phone_number')
                if phone_number:
                    set_call_data("phone_number",phone_number)
                else:
                    print("------------------no phone number-----it can not be-----------------")
                    return send_ending_response()
            if not stage or stage == "start":
                if digits =='1':
                    set_call_data('next_stage','place_digits')
                    return jsonify({
                    'message': "אנא ציין את שימך המלא, את הנושא המבוקש לחברותא,",
                    'required_data_schema': {'topic': 'str', 'name': 'str'},
                }), 200
                elif digits == '2':
                    return send_delete_havruta_requests(phone_number)
                else:
                    return send_ending_response()
            elif stage == 'place_digits':
                    set_call_data('next_stage','place_digits_input')
                    set_call_data_by_id("analysis_response_url","name_and_topic",request.json.get('analysis_response_url'))
                    return jsonify({
                    'message': "לְחַבְרוּתָא טֵלֵפוֹנִית לְחַץ 1, לְחַבְרוּתָא לְפִי עִיר, לְחַץ 2, לְחַבְרוּתָא לְפִי יְשִׁיבָה אוֹ בֵּית כְּנֶסֶת, לְחַץ 3, אִם כָּל הָאֶפְשָׁרוּיוֹת טוֹבוֹת עֲבוּרְךָ, לְחַץ 4 ",
                    'number_of_digits': 1,
                    'required_data_schema': {},
                }), 200
            elif stage == 'place_digits_input':
                if digits =='1':
                    set_call_data("place","phone")
                    set_call_data('next_stage','search_first_message')
                    return send_get_age_respone()
                elif digits =='2':
                    set_call_data('next_stage','age')
                    return jsonify({
                    'message': "באיזה עיר אתה מעוניין לִמְצֹא את החברותא?",
                    'required_data_schema': {'city':'str'},
                }), 200
                elif digits =='3':
                    set_call_data('next_stage','age')
                    return jsonify({
                    'message': "באיזה ישיבה אתה מעוניין שתתקיים החברותא?",
                    'required_data_schema': {'yesiva_name':'str'},
                }), 200
                elif digits =='4':
                    set_call_data("place","all")
                    set_call_data('next_stage','search_first_message')
                    return send_get_age_respone()
            elif stage == 'age':
                set_call_data_by_id("analysis_response_url","place",request.json.get('analysis_response_url'))
                set_call_data('next_stage','search_first_message')
                return send_get_age_respone()
            elif stage == 'search_first_message':
                set_call_data("havruta_age",digits)
                set_call_data('next_stage','search_message_2')
                temp_request = TemporaryRequest(request)
                thread = threading.Thread(target=save_analysis_responses_by_thread,args=(temp_request,))
                thread.start()
                # save_analysis_responses()
                return jsonify({
                    'message': "אני מנתח את המידע ומחפש לְךָ את החברותות המתאימות",
                }), 200
            elif stage == 'search_message_2':
                rejections=find_relevant_havrutas()
                if rejections: return rejections
                return send_relevant_havruta_response()
                
            elif stage == 'send_relevant_havruta_response':
                return send_relevant_havruta_response(digits=request.json.get('digits'))
            elif stage == 'send_delete_havruta_requests':
                return send_delete_havruta_requests(phone_number=get_call_data("phone_number"))
            elif stage == 'ending':
                return send_ending_response()
        except:
            return send_ending_response()


            
            


    return send_ending_response()




def send_ending_response():
    delete_call_data()
    return jsonify({}), 200 # החזר ריק הוא הסימן לסיום השירות

def send_get_age_respone():
    return jsonify({
                'message': "לְחַבְרוּתָא שֶׁל יְלָדִים לְחַץ 1, לְנֹעַר בְּגִיל יְשִׁיבָה קְטַנָּה לְחַץ 2, לְבוֹגְרִים לְחַץ 3, לְשֵׂיבָה וּגְבוּרוֹת לְחַץ 4 , אִם הַכֹּל מַתְאִים לְךָ לְחַץ 5 ",
                'number_of_digits': 1,
                'required_data_schema': {},
            }), 200


def save_analysis_responses_by_thread(temp_request):
    response_urls_list=get_call_data_list("analysis_response_url", request=temp_request)
    response_urls_dict={}
    for response in response_urls_list:
        response_urls_dict.update(response)
    name=get_call_data('name', request=temp_request)
    topic=get_call_data('topic', request=temp_request)
    place=get_call_data('place', request=temp_request)
    if not name and not topic:
        name_and_topic_analysis=wait_for_analysis_response(response_urls_dict['name_and_topic'])
        if name_and_topic_analysis:
            
            set_call_data('name',name_and_topic_analysis.get('analysis_response',{}).get('required_data',{}).get("name"), request=temp_request)
            set_call_data('topic',name_and_topic_analysis.get('analysis_response',{}).get('required_data',{}).get("topic"), request=temp_request)
    # place=get_analysis_response() # זה יעבוד רק בקריאה שמיד אחרי הניתוח
    if not place:
        place_analysis=wait_for_analysis_response(response_urls_dict['place'])
        if place_analysis:
            set_call_data('place',place_analysis.get('analysis_response',{}).get('required_data',{}).get('city',place_analysis.get('yesiva_name',place_analysis.get('place_name',None))), request=temp_request)
            
    set_call_data("analysis_responses_saved","1", request=temp_request)

def find_relevant_havrutas():
    if not get_call_data("analysis_responses_saved"):
        return jsonify({
                'message': "אני עדיין באמצע החיפוש, אנא הַמְתֵּן",
                'number_of_digits': 1,
                'required_data_schema': {},
            }), 200

    phone_number=get_call_data("phone_number")
    name=get_call_data('name')
    topic=get_call_data('topic')
    place=get_call_data('place')
    havruta_age=get_call_data('havruta_age')
    

    fields = {
    'name': name,
    'topic': topic,
    'place': place,
    'havruta_age': havruta_age
    }

# יצירת רשימה של שמות השדות שהם ריקים או None
    empty_fields = [field for field, value in fields.items() if value in [None, ""]]


    if empty_fields:
        # דורש שינוי - כאן ממש מומלץ להחזיר דרישה חדשה רק עבור מה שחסר
        # set_call_data('next_stage',"")
        # set_call_data("analysis_responses_saved","")
        phone_number=get_call_data("phone_number")
        delete_call_data()
        set_call_data("phone_number",phone_number)
        set_call_data('next_stage','start')
        return jsonify({
                'message': "חסרים לי נתונים, אנא חזור על התהליך והשתדל למלא את כֹּל הפרטים, לְחַץ 1 להתחלה",
                'number_of_digits': 1,
                'required_data_schema': {},
            }), 200
    else:
        havruta_id= str(uuid.uuid4())
        set_data_by_id(phone_number,havruta_id, havruta_id)
        set_data_by_id("phone_number",havruta_id, phone_number)
        set_data_by_id("name",havruta_id, name)
        set_data_by_id("topic",havruta_id, topic)
        set_data_by_id("place",havruta_id, place)
        set_data_by_id("havruta_age",havruta_id, havruta_age)


        data_list=get_data_list('topic')
        data_list=get_similarity_results(data_list,topic)
        # השורה הבאה מוציאה מהרשימה את ההצעות של אותו המשתמש
        data_list = [data for data in data_list if (data['id'] != havruta_id and get_data_by_id("phone_number",data['id'])!=phone_number)]
        if place != "all":
            data_list = [data for data in data_list if (get_data_by_id("place",data['id'])=="all" or get_similarity_rate(get_data_by_id("place",data['id']),place)>0.6  )]
            # data_list = [data for data in data_list if (get_data_by_id("place",data['id'])=="all" or get_data_by_id("place",data['id'])==place )]

        if havruta_age != '5':
            data_list = [data for data in data_list if (get_data_by_id("havruta_age",data['id'])=="5" or get_data_by_id("havruta_age",data['id'])==havruta_age )]

        for data in data_list:
            set_call_data_by_id("relevant_havruta_similarity",data['id'],data['similarity'])


def send_relevant_havruta_response(digits="1"):
    last_id=get_call_data('last_id')
    
    
    message=""
    relevant_havrutas=get_call_data_list('relevant_havruta_similarity')
    if not relevant_havrutas:
        message="לֹא נִמְצְאוּ חַבְרוּתוֹת מַתְאִימוֹת, רָשַׁמְתִּי אוֹתְךָ בִּרְשִׁימַת הַחַבְרוּתוֹת, כְּשֶׁתִּמָּצֵא חַבְרוּתָא רֵלֵוַנְטִית, הוּא יִצֹּר אִתְּךָ קֶשֶׁר, אֲנִי מוֹדֶה לְךָ עַל הַשִּׁמּוּשׁ בְּזִירַת הַחַבְרוּתוֹת"
        set_call_data('next_stage','ending')
        return jsonify({
                'message': message
            }), 200
    if not last_id:
        message=f"מצאתי {len(relevant_havrutas)} חברותות מתאימות, תוכל ללחוץ בזמן השמעת הפרטים על 1 למעבר לחברותא הבאה או על 2 לקבלת מספר טלפון"

    if digits == "2":
        phone_number=get_data_by_id("phone_number",last_id)
        phone_number=normalize_phone_number(phone_number)
        return jsonify({
                'message': f"מספר הטלפון הוא ,{phone_number}, אני חוזר {','.join(list(phone_number))}, אני עובר למועמד הבא"
            }), 200


    next_item=get_next_item(relevant_havrutas,last_id)
    if not next_item:
        message="לֹא נִמְצְאוּ יוֹתֵר חַבְרוּתוֹת מַתְאִימוֹת, רָשַׁמְתִּי אוֹתְךָ בִּרְשִׁימַת הַחַבְרוּתוֹת, כְּשֶׁתִּמָּצֵא חַבְרוּתָא רֵלֵוַנְטִית, הוּא יִצֹּר אִתְּךָ קֶשֶׁר, תּוֹדָה עַל הַשִּׁמּוּשׁ בַּשֵּׁרוּת"
        set_call_data('next_stage','ending')
        return jsonify({
                'message': message
            }), 200
    id=list(next_item.keys())[0]
    set_call_data('last_id',id)
    set_call_data('next_stage','send_relevant_havruta_response')
    place =get_data_by_id('name',id)
    place= "טלפוני" if place=="phone" else "בכל מקום אפשרי" if place =="all" else place
    age =get_data_by_id('havruta_age',id)
    age="ילדים" if age=="1" else "נוער"  if age=="2" else "מבוגרים"  if age=="3"  else "שיבה וגבורות"  if age=="4"  else "לא משנה"
    message +=f"{get_data_by_id('name',id)}, מחפש חברותא ל, {get_data_by_id('topic',id)}, מיקום, {place}, הגיל המבוקש הוא ,{age}, " 
    message+= "לְחַץ 1  למעבר לחברותא הבאה או על 2 לקבלת מספר טלפון"
    return jsonify({
                'message': message,
                'number_of_digits':1
            }), 200
    


def send_delete_havruta_requests(phone_number):
    last_id=get_call_data('last_id')
    data_list=get_data_list(phone_number)
    if not data_list:
        set_call_data('next_stage','ending')
        return jsonify({
                'message': "לא נמצאו בקשות שֶׁלְּךָ לחברותא, תודה על השימוש במערכת",
            }), 200
    message=""
    digits=request.json.get('digits')
    if last_id and digits and digits == "2":
        delete_data_by_id(last_id)
        message+= "הרשומה נמחקה,"
    next_item=get_next_item(data_list,key=last_id)
    if not next_item:
        set_call_data('next_stage','ending')
        return jsonify({
                'message':message+ "לא נמצאו בקשות נוספות שֶׁלְּךָ לחברותא, תודה על השימוש במערכת",
            }), 200

    id=list(next_item.keys())[0]
    set_call_data('last_id',id)
    set_call_data('next_stage','send_delete_havruta_requests')
    return jsonify({
            'message': message+f"בקשת חברותא נושא {get_data_by_id('topic',id)}, למחיקה הקש 2 למעבר לרשומה הבאה הקש 1",
            "number_of_digits":1
        }), 200



if __name__ == '__main__':
    app.run(host='0.0.0.0', port=5000)

        

        `;

    
const codeBlockStyle = {
    width: '100%',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    };
  return (
    <div dir="ltr" style={codeBlockStyle}>
  <SyntaxHighlighter language="javascript" style={dark} customStyle={{ backgroundColor: '#000', color: '#fff' }} >
  {code}
   </SyntaxHighlighter></div>
);
};

export default HavrutaExample;
